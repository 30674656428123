<div class="fullJobs">
    <div class="margins">
        <title class="text sizeMainText animateHeader colorBlack bold">{{'App.Jobs.Jobs' | translate}}
        </title>

        <div class="sizeOtherText colorBlack">
            <div class="animateHeader">

                <div class="mt-50px">
                    <mat-accordion>
                        <!-- Position 1 -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.Jobs.Developer' | translate}} C++
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.Jobs.JobDescription1' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line2' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line3' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line4' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line5' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line6' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line7' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line8' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription1Line9' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.SendCVto' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <!-- Position 2 -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.Jobs.Developer' | translate}} C#, .NET
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.Jobs.JobDescription2' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line2' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line3' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line4' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line5' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line6' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line7' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line8' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription2Line9' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.SendCVto' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                        <!-- Position 3 -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.Jobs.Developer' | translate}} Angular 2
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.Jobs.JobDescription3' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line2' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line3' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line4' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line5' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line6' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line7' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line8' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.JobDescription3Line9' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.Jobs.SendCVto' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>

            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>