<div class="fullHome">
    <div class="flex" [ngClass]="{'flex-mobile': isMobile}">
        <title>{{'App.Home.Title' | translate}}</title>

        <!-- Logo -->
        <div class="logoPosition" [ngClass]="{'mobilelogoPosition': isMobile}">
            <video *ngIf="isChrome" autoplay muted loop controls="false" oncanplay="this.play()"
                onloadedmetadata="this.muted = true" class="firstPageVideo"
                [ngClass]="{'mobileFirstPageVideo': isMobile}" poster="./assets/Images/homeImage.webp">
                <source src="./assets/Images/firstPage.mp4" type="video/mp4">
            </video>
            <img *ngIf="!isChrome && isMobile" [ngClass]="{'mobileFirstPageImage': isMobile}"
                [src]="'./assets/Images/homeImage.webp'" class="firstPageImage"
                alt="HR services, hr agency, bpo, human resource, talent hunter, homeCSoft" loading="lazy">
        </div>

        <div class="w100p">

            <h1 class="topTextH1">
                <!-- <span class="image-wrapper shine">  class shineEffect on the img-->
                <img class="img-top" loading="lazy" [src]="'./assets/Images/logoTrans.png'" alt="Csoft Logo" [ngClass]="{'mobilelogo': isMobile}">
                <!-- </span> -->
                <img class="img-top" loading="lazy" [src]="'./assets/Images/Nadpis.webp'" alt="CSoft Europe" [ngClass]="{'mobileCompanyName': isMobile}">

                <span class="topText font1" [ngClass]="{'mobileITHRtext': isMobile}">IT HR Services</span>
                <br *ngIf="!isMobile">
                <br>
                <br>
                <span class="topText font2" [ngClass]="{'mobileTitle': isMobile}">{{'App.Home.Title' | translate}}</span>
                <br *ngIf="!isMobile">
                <br *ngIf="!isMobile">
                <span *ngIf="rotate == 1 && !isMobile" class="topText font2 ">{{'App.Home.LookingForTheBestEmployee' |
                    translate}}</span>
                <br *ngIf="!isMobile">
                <span *ngIf="rotate == 1 && !isMobile" class="topText font2 ">{{'App.Home.OnTheRightPlace' | translate}}</span>
                <br *ngIf="!isMobile">
                <span *ngIf="rotate == 2 && !isMobile" class="topText font2 ">{{'App.Home.WeAreCostEffective' | translate}},
                    {{'App.Home.WeSaveTime' | translate}}, {{'App.Home.WeAreExperts' | translate}}</span>

            </h1>
        </div>
        <!-- FONTS Cooper Black (Capital) and Ariel (small) This is rotation squares-->
        <!-- <div class="positionHomePage">
            <div class="position positionTextAbsolute HomePageMainTextAnimation"
                [ngClass]="{'mobilePosition': isMobile}">
                <div class="positionText">
                    <div>
                        <div>
                            <h1 class="sizeMainText lineHeight25Perc titleBox animationOneRectangle"
                                [ngClass]="{'mobileTitleBox': isMobile, 'mobileSizeMainText': isMobile}">
                                {{'App.Home.Title' | translate}}</h1>
                        </div>
                        <div class="sizeOtherText">
                            <div class="subHeader lineHeight30">

                                <div class="flip-box possitionSecondRectangle animationTwoRectangle"
                                    [ngClass]="{'mobileSecondBoxPosition': isMobile, 'mobileflip-box': isMobile}">
                                    <div class="flip-box-inner">
                                        <div class="box350px" [ngClass]="{'mobileBoxTwo': isMobile}">
                                            <p class="bestEmployeePosition"
                                                [ngClass]="{'mobileBestEmployeePosition': isMobile}">
                                                {{'App.Home.LookingForTheBestEmployee' | translate}}
                                            </p>
                                        </div>
                                        <div class="flip-box-back box350px"
                                            [ngClass]="{'displayTable': isMobile, 'mobileBoxTwo': isMobile}">
                                            <p class="weAreCostEffective"
                                                [ngClass]="{'mobileWeAreCostEffective': isMobile}">
                                                {{'App.Home.WeAreCostEffective' | translate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <br>

                                <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                                    [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                                    <div class="flip-box-inner ">
                                        <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                                            <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                                                {{'App.Home.OnTheRightPlace' | translate}}</p>
                                        </div>
                                        <div class="flip-box-back box250px"
                                            [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                                            <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                                                {{'App.Home.WeSaveTime' | translate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                               
                                <br>

                                <div class="flip-box3 possitionFourthRectangle animationFourRectangle"
                                    [ngClass]="{'mobileFourthBoxPosition': isMobile, 'mobileflip-box3': isMobile}">
                                    <div class="flip-box-inner ">
                                        <div class="flip-box-front transperantBox200"
                                            [ngClass]="{'transperantmobileBoxFour': isMobile}">
                                            <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                                            </p>
                                        </div>
                                        <div class="flip-box-back box200px"
                                            [ngClass]="{'displayTable': isMobile, 'mobileBoxFour': isMobile}">
                                            <p class="weAreExperts" [ngClass]="{'mobileWeAreExperts': isMobile}">
                                                {{'App.Home.WeAreExperts' | translate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div loading="lazy" class="visibility-auto mt-180 whiteRectangle rectanglePos"
        [ngClass]="{'mt-80': isMobile, 'w320': isMobile}">
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout bold justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Slogan' | translate}}
        </h2>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.PartOfAbout' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs1' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs2' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs3' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs4' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs5' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs6' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs7' | translate}}
        </h1>
        <h1
            class="visibility-auto sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.AboutUs8' | translate}}
        </h1>

        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout bold justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text1' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text2' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions  HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text3' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text4' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text5' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.Text6' | translate}}
        </h2>

    </div>
    <br>
    <div loading="lazy" class="visibility-auto whiteRectangle rectanglePos" [ngClass]="{'w320': isMobile}">
        <h1
            class="visibility-auto bold sizeOtherTextBlack subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices1' | translate}}
        </h1>

        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices2' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices3' | translate}} <a routerLink="/hrservices">{{'App.Home.Here' | translate}}</a>
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions  HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices4' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices5' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices6' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices7' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsHRServices8' | translate}}
        </h2>

        <h2
            class="visibility-auto bold sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO1' | translate}}
        </h2>
        <h2
            class="sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO2' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO3' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO4' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO5' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO6' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO7' | translate}}
        </h2>
        <h2
            class="visibility-auto sizeOtherTextBlack subHeaderAbout justify textPositions HomePageMainTextAnimation2 standartFont justyfive">
            {{'App.Home.WhatIsBPO8' | translate}}
        </h2>

    </div>

</div>
<br>
<div *ngIf="isChrome" class="positionMoreThan">
    <h1 class="moreThan" [ngClass]="{'mobilemoreThan':isMobile}"> {{'App.Home.MoreThan' | translate}} </h1>
    <div class="moreThan number" [ngClass]="{'mobilemoreThan':isMobile, 'mobileNumber': isMobile}"></div>
    <h1 class="moreThan" [ngClass]="{'mobilemoreThan':isMobile}"> {{'App.Home.satisfiedCustomersWorldwide' | translate}}
    </h1>
</div>

<div *ngIf="!isChrome" class="positionMoreThan">
    <h1 class="moreThan" [ngClass]="{'mobilemoreThan':isMobile}"> {{'App.Home.MoreThan' | translate}} </h1>
    <div class="moreThan mlrOtherBrowsers" [ngClass]="{'mobilemoreThan':isMobile}">2000</div>
    <h1 class="moreThan" [ngClass]="{'mobilemoreThan':isMobile}"> {{'App.Home.satisfiedCustomersWorldwide' | translate}}
    </h1>
</div>

<br>
<br>
<br>

<h1 class="visibility-auto hiddenSEO">
    <p href="https://www.zaplata.bg/">zaplata.bg</p>
    <p href="https://www.jobs.bg/">jobs.bg</p>
    <p href="https://dev.bg/company/csoft-europe/">dev.bg</p>
    <p href="https://www.monster.com/">monster.com</p>
    <p
        href="https://clutch.co/profile/csoft-europe?_gl=1*yap3er*_ga*MTEzMjQ5ODAxOC4xNjc1MDMwODM5*_ga_D0WFGX8X3V*MTY3NTEwMzgwMy40LjEuMTY3NTEwNjg3MC41Mi4wLjA.*_fplc*N1JpOHRVMHQlMkJyeDltRUtoS08lMkZvbE1uV2RGZEElMkJJVyUyQnhvTk4zTUtnJTJGS3VJSVoxNkFGSDRhRU5OViUyRllQZzUzTzNyZlRHd1FoY2hiSFZ1Tjd0MHBsYllIbnFYJTJGWGRWeU9vaHFFekZyaWxHQmZFWDlLa0I4bHhZbzZKRnFRd0ElM0QlM0Q.#highlights">
        clutch.com</p>
    <p href="https://www.yellowpages.net/profil_152082_csofteu_sofia.html">yellowpages.net</p>
    <p href="https://www.reddit.com/user/C-Soft/comments/lhnoa5/csoft_the_best_hr_and_software_company/">reddit.com</p>
    <p href="https://www.capital.bg/">capital.bg</p>
</h1>

<h1 class="visibility-auto hiddenSEO">
    hr agency bulgaria,
    hr agency sofia,
    hr agency usa price,
    hr agency near me,
    it hr services bulgaria,
    it hr services,
    strategic hr & it services,
    hr shared services,
    hr services price list,
    hr services sofia,
    quality hr services,
    bpo solutions,
    bpo hr services,
    talent hunter solutions,
    talent hunter sofia,
    talent hunter bulgaria,
    human resource management,
    hr management,
    human resource specialist,
    hr specialist,
    human resource development center,
    hr услуги,
    подбор на персонал,
    подбор на IT специалисти,
    подбот на IT кадри,
    подбор на ИТ специалисти,
    подбор на ит кадри,
    hr услуги,
    най-добрата hr агенция,
    най-добри hr агенции,
    топ hr агенции българия,
    топ hr агенции софия,
    топ hr агенция софия,
    the best hr agency sofia,
    the best hr services sofia,
    the best hr agencies in bulgaria,
    the best hr agencies in sofia,
    what is HR services,
    what is BPO,
    what is BPO services,
    какво е hr услуги,
    какво е BPO услуги,
    какво е BPO,
    HR services,
    human resources,
    recruitment,
    talent acquisition,
    employee management,
    payroll services,
    employee retention,
    performance evaluation,
    benefits administration,
    training and development,
    workforce development,
    outsourcing,
    compliance,
    global HR,
    hr tech,

    Human resources solutions,
    Personnel management services,
    Workforce management services,
    Talent management services,
    Recruitment and staffing services,
    Employee management solutions,
    People management services,
    Payroll and benefits administration services,
    Performance management solutions,
    Learning and development services,
    Compliance and regulatory services,
    Global HR solutions,
    HR outsourcing services,
    HR consulting services,
    Organizational development solutions,

    Personnel,
    Manpower,
    Workforce,
    Staff,
    Employees,
    Team,
    Labor,
    Personnel management,
    People,
    Human capital,
    Talent,
    Organizational resources,
    Employee resources,
    Human assets,
    Personnel resources,

    Talent acquisition,
    Staffing,
    Hiring,
    Employment,
    Selection,
    Onboarding,
    Headhunting,
    Candidate sourcing,
    Talent search,
    Applicant screening,
    Job placement,
    Human capital acquisition,
    Recruitment process outsourcing,
    Recruitment marketing,
    Candidate management,

    Human resources consultancy,
    HR firm,
    Personnel agency,
    Recruitment agency,
    Staffing agency,
    Talent management agency,
    Employment agency,
    HR outsourcing provider,
    HR services provider,
    HR solutions company,
    HR consulting firm,
    HR management company,
    HR staffing agency,
    HR talent agency,
    HR outsourcing agency,
</h1>

<h1 class="visibility-auto hiddenSEO">
    Ние сме специализирани в предоставянето на иновативни ИТ решения за човешки ресурси, включително търсене на таланти,
    услуги за човешки ресурси, набиране на персонал, подбор на таланти, управление на служители, задържане на служители,
    оценка на представянето, администриране на предимства, обучение и развитие, и развитие на работната сила.",
    "В CSoft Europe IT HR Services разбираме, че аутсорсинг услугите могат да бъдат предизвикателство. Ето защо ние
    предлагаме цялостни решения, които ви помагат да управлявате вашите HR процеси с лекота. Независимо дали имате нужда
    от поддръжка или глобална помощ за човешки ресурси, нашият екип от ИТ и HR експерти е тук, за да ви помогне.",
    "Нашият афинитет към ИТ иновациите и технологиите гарантира, че предоставяме най-модерните HR услуги в САЩ,
    Швейцария и България. В резултат на това можем да ви помогнем да трансформирате вашите човешки ресурси в
    стратегическо предимство, което ви отличава от вашите конкуренти.",
    "В CSoft Europe IT HR Services вярваме, че подбора на таланти е от съществено значение за изграждането на успешен
    бизнес. Ето защо ние предлагаме специализирани услуги за търсене на таланти, за да ви помогнем да намерите
    най-добрите таланти за вашата организация. Нашият екип от ИТ и HR експерти по подбор на персонал използва иновативни
    техники за идентифициране и привличане на кандидати от най-високо ниво, които отговарят на вашата бизнес култура и
    нужди.",
    "В CSoft Europe IT HR Services вярваме, че задържането на служителите е от решаващо значение за дългосрочния успех
    на всеки бизнес. Нашите услуги за задържане на служители са предназначени да ви помогнат да поддържате най-добрите
    си служители ангажирани и мотивирани. Ние предоставяме персонализирани решения, които включват оценка на
    представянето, администриране на предимства, обучение и развитие, и развитие на работната сила.",
    "Гордеем се с предоставянето на изключително обслужване, което отговаря и надхвърля очакванията на нашите клиенти.
    Ако търсите агенция за човешки ресурси, която може да ви помогне да постигнете целите си в областта на човешките
    ресурси и да превърнете вашите човешки ресурси в стратегическо предимство, тогава CSoft Europe IT HR Services е
    правилният избор за вас.",
    "В CSoft Europe IT HR Services ние винаги проучваме нови начини за използване на ИТ технологиите, за да предоставим
    най-иновативните решения за човешки ресурси. Нашият екип от експерти е винаги в крак с най-новите ИТ иновации и
    технологии, за да гарантира, че нашите клиенти получават най-добрите услуги. Нашите HR технически решения са
    предназначени да ви помогнат да управлявате вашите HR процеси с лекота, а нашата ИТ HR агенция е тук, за да ви
    предостави необходимата подкрепа и насоки.",
    "Ако търсите ИТ агенция за човешки ресурси, която може да предостави най-изчерпателните и авангардни решения за
    човешки ресурси, не търсете повече от CSoft Europe HR Services. Свържете се с нас днес, за да научите повече за това
    как можем да ви помогнем да постигнете вашите HR цели."

    "The reason is that most HR companies HAVE NO IDEA what actually the IT specialists
    doing. They did not know how UNDERSTANDABLE to describe the requirements about the position. Some times they even
    marked some unnecessary technologies as mandatory, which may reject a lot of good candidates. If you want to skip
    this misleading requirements in the job description you have to use PROFESIONAL IT HR Company like CSoft Europe,
    because WE HAVE IT SPECIALISTS, HR PROFESSIONALIST and AI SOFTWARE which works perfect together. From the research
    of the IT market, interviewing it appropriate person, until finding the best candidate and represent it in front of
    you.",
    "Have you seen how some of your new employee stay away from other members?",
    "The reasons can be a few, but the main problem comes from the HR agency which you
    used. They just skipped this part from the interview like psychology questions and situations. Some times the
    potential candidate can be GOOD SPECIALIST, but he CANNOT COLLABORATE with other team members, because he closed its
    self from outer world. This MAY AFFECT YOUR BUSINESS, to SLOW your speed of production and so on. Mandatory step
    from the first meeting between HR agency and your company is to be defined what kind of candidate will be
    appropriate for you. Does the company provide projects and tasks which can be finished from 1 (one) IT specialist or
    you looking for team player? If the HR agency just want to understand what kind of IT specialist you looking for and
    that is all – STAY AWAY from this agency, SAVE YOUR MONEY. They LOOKING for your MONEY, not for the best employee
    for you.",
    "Do you looking for some IT specialist ASAP/ for today? If you looking for somebody
    EMERGENCY, you have to know that FAST JOB = FAST FAILURE. The chance to found for tomorrow the best employee in the
    IT field is almost 0%.",
    "1. There is a lot of IT specialists who looking for job changing, but the chance to
    pick up the correct one only from 2-3 interviewed persons is 0% - 1%. The reason: a lot of people looking only for
    BIG money as a specialist, but their knowledges are equal to 0.",
    "2. The HR agency likes clients like this company, because they just will pick up
    some body and will put some GOOD WORDS in his mouth which to make better impression in front of you and VOALA. You
    think that the best IT specialist is in front of you. Actually you going to pay for some one who is far away from
    your idea for perfect candidate.",
    "3. Most HR agencies explained you how they have aways a few SOFTWARE DEVELOPERS who
    can be provided for your needs directly TOMORROW MORNING. This is bullshit. They just wants to GET YOUR MONEY
    TOMORROW MORNING, but the person who have to work on your project will be some LOW QUALIFICATED/ LOW EXPERIANCED
    developer or sometimes your employee will works on 2 different projects at the same time without your knowledge.
    This is not good idea, because is not so easy one developer to change fast from one code to another. If you don`t
    want to be scammed just call us. CSoft Europe is the correct partner for you.",
    "4. When you looking for some IT specialist be patient. Don`t show that you hurry up.
    It takes between 1-3 months some IT specialist to change his current position. Another factor about this is the time
    of the year. Most of IT specialists are ready to change his current job in SPRING or AUTUMN. That is the reason the
    months MARCH-MAY and SEPTEMBER – NOVEMBER to be most appropriate time to be found the best employee for you. If you
    looking for IT specialist out of these months from GOOD IT HR agency, then your chance is not so big. Only 5-10%
    from IT specialists are free or ready to change their jobs out of these months. If you are in this GOLDEN months
    then 25-35% from the IT specialist are ready to change their current job.",
    "Does your HR company provide you different bonuses?",
    "Most IT HR companies did not provide any kind of bonuses to their clients. CSoft
    Europe always provide to the clients different bonuses which can be used. Also there is additional bonuses out of
    the standard one in the web site, which can appear in different time from the year. You can sign up for our
    additional bonuses HERE",
    "SubscribeBonuses": "Subscribe for bonuses!"
</h1>

<h1 class="visibility-auto hiddenSEO">
    human resource management,
    temp agencies near me,
    human rights,
    temp agency,
    recruiter,
    Rubi,
    hr software,
    programming languages,
    UML diagrams,
    hris system,
    zaplata 12.1K,
    5500 form,
    5500 forms,
    обява за работа,
    shrm,
    hr online,

    executive director,
    product owner,
    executive director,
    exec director,
    product owner,
    human resources jobs,
    job agencies near me,
    hr manager,
    recruitment agencies,
    job recruitment,
    headhunting,
    hr department,
    what is hr,
    human resource planning,
    hr consulting,
    hr software,
    human resources salary,
    human resources definition,
    hr meaning,
    hr generalist,
    human resourses,
    hr certification,
    what is human resource management,
    human resources job description,
    resource management,
    entry level,
    hr info,
    hr policies,
    human resource development,
    hr courses,
    hr internships,
    hr manager job description,
    employee relations,
    hr college,

    C,
    tester,
    Python,
    Ruby,
    SQL,
    DB,
    administrator,
    project manager,
    CEO,
    C++,
    admin,
    PHP,
    JS,
    AngularJS,
    Director,
    C#,
    software developer,
    MS SQL,
    админ,
    Team lead,
    DB,
    regional manager,
    IT manager,
    regional manager,
    employee hr software,
    hr services,
    software hr,
    IT specialist,
    автобиография,
    cv template,
    cv бланка,
    executive manager,
    it recruiter,
    it recruiter,
    executive manager,
    hris software system,
    джобс бг бургас,
    дистанциона работа,
    PHP,
    SQL,
    headhunting,
    software recruiter,
    заплата бг пловдив,
    titles in a business,
    автобиография образец,
    jobs bg cv,
    бг мапс варна,
    дистанционна работа,
    hr by hr,
    CEO,
    AngularJS,
    автобиография за работа,
    hr,
    джобс бг велико търново,
    заплата бг варна,
    подбор на персонал,
    счетоводител,
    счетоводител варна,
    счетоводител софия,
    подбор на персонал,
    hrms software company,
    Software manager,
    Angular2,
    Angular 2,
    бази данни,
    директор,
    агенция за подбор на персонал,
    как да си намеря работа,
    business job titles,
    hr functions,
    hr system,
    human resources degree,
    hr services,
    hr solutions,
    human capital management,
    hr recruiter,
    hr specialist,
    hr assistant,
    higher ed,
    personnel management,
    strategic human resource management,
    hr strategy,
    hr administrator,
    functions of human resource management,
    hr questions,
    department of human resources,
    hr director,
    hr training,
    hr news,
    hr magazine,
    human resource information system,
    human resource management system,
    hr consultancy,
    human resource management salary,
    human resource management definition,
    hr executive,
    what does human resources do,
    hr officer,
    hr companies,
    masters in human resources,
    human resource management courses,
    hr outsourcing,
    hr careers,
    hr metrics,
    hr vacancies,
    hr group,
    hr resume,
    human resource planning process,
    importance of human resource management,
    define human resources,
    hr com,
    hr professional,
    human resources development,
    hr practices,
    human ressources,
    hr consulting firms,
    hr certifications,
    jobs in hr,
    hr audit,
    hr manager jobs,
    human resource definition,
    hr process,
    hr office,
    human resource management books,
    hr roles,
    human resource management jobs,
    international human resource management,
    role of hr,
    hr positions,
    definition of human resource management,
    global hr,
    definition of human resources,
    meaning of hr,
    what are human resources,
    human resource management degree,
    hr management software,
    hr tools,
    human resources programs,
    hr support,
    careers in human resources,

    езици за програмиране,
    агенция за подбор на персонал,
    как да си намеря работа,
    business job titles,
    zaplata.bg varna,
    въпроси за интервю,
    employee software,
    психолози бургас,
    работа в варна,
    senior management job titles,
    заплата бг русе,
    работа от дома,
    product owner,
    project owner,
    оперативен счетоводител,
    автобиография cv,
    director,
    директор,
    db admin,
    дб админ,
    db админ,

    HR services for small business, hr service for it industry,You looking for the best employee and you want to save
    money?,
    You are on the right place,
    CSoft is an innovative IT and Business Process Outsourcing (BPO) company, solutions and product provider for your
    business. Our mission is to work at the highest standards in order to provide solutions which meet the specific
    business expectations and this way to help the client`s business,
    We are cost effective,
    We save time,
    We are experts,
    You have a business idea which needs a software,
    Staff selection,
    Selection of expert levels,
    selection of management staff,
    Selection of IT specialists,
    Temporary staff,
    Process of hiring,
    Software Services,
    Услуги,
    HR услуги,
    Обучения,
    Подбор на персонал,
    Подбор на експертни нива,
    Подбор на ръководен персонал,
    Подбор на IT специалисти,
    Временна заетост,
    Софтуерни услуги,

</h1>