<div>
    <div class="fullProducts">
        <div>
            <h3 class="text">Products:</h3>
            <title class="text">Products:</title>
        </div>
    </div>

    <div class="textBody">
        <span>
            <p>
                We offer an ERP (Enterprise Resource Planning) software for Small and Middle business.
            </p>
            <p>
                Our ERP software can be customizated for any kind business.
            </p>

            <div class="fullProducts">
                <div>
                    <h3 class="textBody">Modules:</h3>
                </div>
            </div>


            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Production planning and management
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Crop management
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Seeds
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Harvest
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Productions
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Wine
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Production
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Inventory
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Vessels
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Vineyards
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Sales
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Expenses
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Finance
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Sales
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Expenses
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Assets
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Transfer
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Business trip
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> In a country
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Abroad
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Resources
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Articles
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Counterparties
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Storages
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Employee
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Machinery
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Fields
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Inventory
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Planning
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Reminder
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Analysis
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Dashboards
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> References</p>
            <p class="modules3">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#ed1b24" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Reference invoice
            </p>
            <p class="modules3">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#ed1b24" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Obligations
            </p>
            <p class="modules3">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#ed1b24" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> E-mails
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Daily tasks
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Reports
            </p>

            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Documents
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Deliverer request
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Client request
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Price list deliverer
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Price list client
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Trade documents
            </p>
            <p class="modules2">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#fef200" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>
                </svg> Trade documents scheme
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Contract/Offer
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Export invoices
            </p>

            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Nomenclatures
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> MRP
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Payment methods
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Payment periods
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Countries
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Cities
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Exchange rate
            </p>

            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Settings
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Import pattern
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Document numbers
            </p>

            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Users
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Logins accounts
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Rights
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Change password
            </p>

            <div class="modules">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#2cb1d2" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Help
            </div>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> Program help
            </p>
            <p class="modules1">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="15px" viewBox="0 0 2240 560"
                    preserveAspectRatio="xMidYMid meet">
                    <g id="layer101" fill="#a0ce16" stroke="none">
                        <path d="M0 280 l0 -280 1120 0 1120 0 0 280 0 280 -1120 0 -1120 0 0 -280z" />
                    </g>

                </svg> About
            </p>



        </span>
    </div>
</div>

<br>
<br>
<br>