<div class="fullContent" [ngClass]="{'displayFlex': !isMobile}">
    <div class="LoginForm" [ngClass]="{'alignCenter': !isMobile}">
        <form [formGroup]="form" *ngIf="!isLogged">
            <div [ngClass]="{'alignCenter': !isMobile}">
                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Email' | translate}}</label>
                    <input class="input shadow" id="email" formControlName="loginEmail" required type="text"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['loginEmail'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                    <label class="redText"
                        *ngIf="isWrongUser">{{'App.Contacts.WrongUser' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Pass' | translate}}</label>
                    <input class="input shadow" id="pass" formControlName="loginPass" required type="password"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['loginPass'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="fullContent" [ngClass]="{'displayFlex alignCenter': !isMobile}">
                    <div class="textContacts textBoxes betweenButtons">
                        <button id="btnLogin" class="button shadow" type="submit"
                            (click)="login()">{{'App.Contacts.Login' | translate}}</button>
                    </div>
                </div>

            </div>
        </form>

        <form [formGroup]="form2" *ngIf="isLogged">
            <div [ngClass]="{'alignCenter': !isMobile}">
                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Text' | translate}}</label>
                    <textarea id="message" formControlName="sendText" required class="textarea shadow" type="text"
                        aria-multiline="true" [ngClass]="{'w350px': isMobile}" rows="18" cols="85" #filterName
                        name="filterName"></textarea>
                    <label class="redText"
                        *ngIf="form2.controls['sendText'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.To' | translate}}</label>
                    <textarea id="messageTo" formControlName="sendTo" required class="textarea shadow" type="text"
                        aria-multiline="true" [ngClass]="{'w350px': isMobile}" rows="18" cols="85" #filterName
                        name="filterName"></textarea>
                    <label class="redText"
                        *ngIf="form2.controls['sendTo'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="fullContent" [ngClass]="{'displayFlex alignCenter': !isMobile}">
                    <div class="textContacts textBoxes betweenButtons">
                        <button id="btnSend" class="button shadow" type="submit"
                            (click)="send()">{{'App.Contacts.Send' | translate}}</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>