<ng-container *ngIf="display$ | async as display">
    <section [ngClass]="{'open': display === 'open'}">
        <div (click)="$event.stopPropagation()">
            <br>
            <h1>Cookie Policy</h1>
            <p>{{'App.Cookies.ShortCookieMessage' | translate}}</p>
            <a routerLink="/cookies-page">Cookies Polici</a>
            <br>
            <br>
            <button class="buttonSize" (click)="close()">OK</button>
        </div>
    </section>
</ng-container>