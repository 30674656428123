import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalDialogService } from '../modal-dialog.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { createTransport } from 'nodemailer';
import { google } from 'googleapis';

//declare var gapi: any;
//declare function sendEmail(from, to, subject, text, html): any;
//declare function sendEmail(): any;
//declare function test(): any;

@Component({
  selector: 'app-modal-ask-email',
  templateUrl: './modal-ask-email.component.html',
  styleUrls: ['./modal-ask-email.component.scss']
})
export class ModalAskEmailComponent implements OnInit {

  public missingName: boolean;
  public missingEmail: boolean;
  displayEmail$: Observable<'open' | 'close'>;

  constructor(private modalServiceEmail: ModalDialogService, private router: Router, public httpCli: HttpClient) {
    //this.oAuth2Client.setCredentials({ refresh_token: this.REFRESH_TOKEN });
  }

  ngOnInit(): void {
    this.displayEmail$ = this.modalServiceEmail.watchEmail();
  }

  close(isOK: boolean) {
    this.missingName = false;
    this.missingEmail = false;
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (isOK) {
      debugger;
      if ((<HTMLInputElement>document.getElementById("clientName")).value !== '' && (<HTMLInputElement>document.getElementById("clientEmail")).value !== '' && regexp.test((<HTMLInputElement>document.getElementById("clientEmail")).value)) {

        //Give me data
        let result: boolean = false;
        //Send data to the backend server
        this.httpCli.post<boolean>('/api/getClientEmail', { name: (<HTMLInputElement>document.getElementById("clientName")).value, clientEmail: (<HTMLInputElement>document.getElementById("clientEmail")).value, company: (<HTMLInputElement>document.getElementById("clientCompany")).value }).subscribe(data => {
          result = data;
          if (result.toString().includes('Thank you!')) {
            alert('Thank you!');
          }
          else {
            alert('Sorry. Please contact us via our E-mail!');
          }
        })
        //OPEN PRICES
        this.router.navigate(['/our-prices']);
        this.modalServiceEmail.closeEmail();
      } else {
        if ((<HTMLInputElement>document.getElementById("clientName")).value === '') {
          this.missingName = true;
        }
        if ((<HTMLInputElement>document.getElementById("clientEmail")).value === '' || !regexp.test((<HTMLInputElement>document.getElementById("clientEmail")).value)) {
          this.missingEmail = true;
        }
      }
    } else {
      this.modalServiceEmail.closeEmail();

    }
  }
}
