import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScreenResolutionService {

  constructor() { }

  public isMobile(): boolean {
    if (window.innerWidth <= 800) {
      return true;
    }
    else {
      return false;
    }
  }
}
