import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { ClientsComponent } from './clients/clients.component';
import { TeamComponent } from './team/team.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ProductsComponent } from './products/products.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HRServicesComponent } from './hrservices/hrservices.component';
import { TrainingServicesComponent } from './training-services/training-services.component';
import { SoftwareServicesComponent } from './software-services/software-services.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { CookiesPageComponent } from './cookies-page/cookies-page.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';

import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
// import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, 
//   MatFormFieldModule, MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, 
//   MatSlideToggleModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { PricesComponent } from './prices/prices.component';
import { AppRoutingModule } from './app-routing.module';
import { JobsComponent } from './jobs/jobs.component';
import { ActualPricesComponent } from './actual-prices/actual-prices.component';
import { ModalAskEmailComponent } from './modal-ask-email/modal-ask-email.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalAskEmailSubscribeComponent } from './modal-ask-email-subscribe/modal-ask-email-subscribe.component';
import { SendEmailMarketingComponent } from './send-email-marketing/send-email-marketing.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderFooterComponent,
    HomeComponent,
    NewsComponent,
    ClientsComponent,
    TeamComponent,
    ServicesComponent,
    AboutComponent,
    ContactComponent,
    ProductsComponent,
    HRServicesComponent,
    TrainingServicesComponent,
    SoftwareServicesComponent,
    CookiesPageComponent,
    ModalDialogComponent,
    PricesComponent,
    JobsComponent,
    ActualPricesComponent,
    ModalAskEmailComponent,
    ModalAskEmailSubscribeComponent,
    SendEmailMarketingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCRRvWXJ63yN_UHkAdXStahTaWoEuB6MCg"
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatExpansionModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}