import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './home/home.component';
import { NewsComponent } from './news/news.component';
import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { TeamComponent } from './team/team.component';
import { ProductsComponent } from './products/products.component';
import { HRServicesComponent } from './hrservices/hrservices.component';
import { TrainingServicesComponent } from './training-services/training-services.component';
import { SoftwareServicesComponent } from './software-services/software-services.component';
import { CookiesPageComponent } from './cookies-page/cookies-page.component';
import { PricesComponent } from './prices/prices.component';
import { JobsComponent } from './jobs/jobs.component';
import { ActualPricesComponent } from './actual-prices/actual-prices.component';
import { SendEmailMarketingComponent } from './send-email-marketing/send-email-marketing.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'news', component: NewsComponent },
  { path: 'about', component: AboutComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'prices', component: PricesComponent },
  { path: 'team', component: TeamComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'hrservices', component: HRServicesComponent },
  { path: 'training-services', component: TrainingServicesComponent },
  { path: 'software-services', component: SoftwareServicesComponent },
  { path: 'cookies-page', component: CookiesPageComponent },
  { path: 'jobs', component: JobsComponent },
  { path: 'our-prices', component: ActualPricesComponent },
  { path: 'send-email-marketing', component: SendEmailMarketingComponent },
  { path: '**', component: HomeComponent }, //By default Home page will be loaded
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })], //# symbol in URL
  exports: [RouterModule]
})
export class AppRoutingModule { }
