<div class="fullClients">
    <div>
        <title class="text">Clients:</title>
    </div>

    <div class="positionIcons">
        <div class="subHeader lineHeight30" [ngClass]="{'mobileMargins': isMobile, 'desktopMargin': !isMobile}">

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/beesoft.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/beesoft.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/businesscloud.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/businesscloud.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/cocacola.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/cocacola.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/cyberside.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/cyberside.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/ittreasure.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/ittreasure.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/musicsoft.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/musicsoft.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/simcom.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/simcom.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/toshglass.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/toshglass.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

            <div class="flip-box2 possitionThirdRectangle animationThreeRectangle"
                [ngClass]="{'mobileThirthBoxPosition': isMobile, 'mobileflip-box2': isMobile}">
                <div class="flip-box-inner ">
                    <div class="flip-box-front box250px" [ngClass]="{'mobileBoxThree': isMobile}">
                        <p class="onTheRightPlace" [ngClass]="{'mobileOnTheRightPlace': isMobile}">
                            <img class="blackwhite" [ngClass]="{'mobileClientSize': isMobile}"
                                [src]="'./assets/Images/wolfy.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                    <div class="flip-box-back box250px"
                        [ngClass]="{'displayTable': isMobile, 'mobileBoxThree': isMobile}">
                        <p class="weSaveTime" [ngClass]="{'mobileWeSaveTime': isMobile}">
                            <img [ngClass]="{'mobileClientSize': isMobile}" [src]="'./assets/Images/wolfy.webp'"
                                alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="250px" height="250px" loading="lazy">
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<br>
<br>
<br>