import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  private display: BehaviorSubject<'open' | 'close'> =
    new BehaviorSubject('close');

  private displayEmail: BehaviorSubject<'open' | 'close'> =
    new BehaviorSubject('close');

  watch(): Observable<'open' | 'close'> {
    return this.display.asObservable();
  }

  open() {
    this.display.next('open');
  }

  close() {
    this.display.next('close');
  }

  watchEmail(): Observable<'open' | 'close'> {
    return this.displayEmail.asObservable();
  }

  openEmail() {
    this.displayEmail.next('open');
  }

  closeEmail() {
    this.displayEmail.next('close');
  }
}