<div class="fullServices">
    <div class="margins">
        <!-- <h1 class="text sizeMainText animateHeader bold">{{'App.SoftwareServices.SoftwareServices' | translate}}</h1> -->
        <title class="text sizeMainText animateHeader bold">{{'App.SoftwareServices.SoftwareServices' | translate}}</title>

        <div class="displayFlex contentMarginLeft displayBlock mt-50px">
            <div class="marginLeft" [ngClass]="{'displayMobile': isMobile}">
                <p class="sizeOtherText whiteRectangle standartFont justyfive lineHeight" >
                    {{'App.SoftwareServices.HaveYouEverUse' | translate}}
                    <br>
                    {{'App.SoftwareServices.WeOfferSoftware' | translate}}
                    <br>
                    {{'App.SoftwareServices.TheCompanyProvide' | translate}}
                    <br>
                    {{'App.SoftwareServices.OurSoftware' | translate}}
                </p>

                <div class="monitorAnimation">
                    <img [src]="'./assets/Images/monitor.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, monitor" width="300px" height="auto" loading="lazy">
                    <br>
                    <img [src]="'./assets/Images/monitor2.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, monitor2" width="300px" height="auto" loading="lazy">
                </div>
            </div>

            <div class="imgAndButton">


                <div>
                    <div>
                        <table id="softwareProducts">
                            <tr>
                                <th> {{'App.SoftwareServices.TableCol1Header' | translate}}</th>
                                <th> {{'App.SoftwareServices.TableCol2Header' | translate}}</th>
                                <th> {{'App.SoftwareServices.TableCol3Header' | translate}}</th>
                            </tr>
                            <tr>
                                <td><h3>{{'App.SoftwareServices.TableSoft1' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.SoftwareServices.AgroP1' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AgroP2' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AgroP3' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AgroP4' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AgroP5' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AgroP6' | translate}}</p>
                                </td>
                                <td>5000 Euro</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.SoftwareServices.TableSoft2' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.SoftwareServices.WineP1' | translate}}</p>
                                    <p>{{'App.SoftwareServices.WineP2' | translate}}</p>
                                    <p>{{'App.SoftwareServices.WineP3' | translate}}</p>
                                    <p>{{'App.SoftwareServices.WineP4' | translate}}</p>
                                    <p>{{'App.SoftwareServices.WineP5' | translate}}</p>
                                    <p>{{'App.SoftwareServices.WineP6' | translate}}</p>
                                </td>
                                <td>5000 Euro</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.SoftwareServices.TableSoft3' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.SoftwareServices.AnimalsP1' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AnimalsP2' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AnimalsP3' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AnimalsP4' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AnimalsP5' | translate}}</p>
                                    <p>{{'App.SoftwareServices.AnimalsP6' | translate}}</p>
                                </td>
                                <td>5000 Euro</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.SoftwareServices.TableSoft4' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.SoftwareServices.HRP1' | translate}}</p>
                                    <p>{{'App.SoftwareServices.HRP2' | translate}}</p>
                                </td>
                                <td>2000 Euro</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.SoftwareServices.TableSoft5' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.SoftwareServices.KinderGardenP1' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP2' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP3' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP4' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP5' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP6' | translate}}</p>
                                    <p>{{'App.SoftwareServices.KinderGardenP7' | translate}}</p>
                                </td>
                                <td>5000 Euro</td>
                            </tr>

                        </table>
                    </div>

                    <button class="button shadow"
                        routerLink="/contact">{{'App.HRServices.ContactUs' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<br>
<br>
<br>