<app-header-footer></app-header-footer>



<!-- S tozi red opredeliame koe menu kakvo da pokazva -->
<div class="loaderPosition" *ngIf="showLoadingIndicator">
    <img class="loaderPosition" [src]="'./assets/Icons/loader.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, loader" width="60px" height="60px">

</div>

<router-outlet></router-outlet>
<app-modal-dialog></app-modal-dialog>
<!-- <app-home></app-home>
<app-news></app-news> -->