<div class="fullServices">
    <div class="margins">
        <!-- <h1 class="text sizeMainText animateHeader colorBlack bold">{{'App.HRServices.HRServices' | translate}}</h1> -->
        <title class="text sizeMainText animateHeader colorBlack bold">{{'App.HRServices.HRServices' | translate}}
        </title>

        <div class="sizeOtherText colorBlack">
            <div class=" animateHeader">

                <div class="visibility-auto flex" [ngClass]="{'flexDirection': isMobile}">
                    <div class="offers whiteRectangle rectanglePos mt-50px" [ngClass]="{'mt-0': isMobile}">
                        <h1 class="bold">{{'App.HRServices.MinimalEmployee' | translate}}</h1>
                        <h1 class="bold">{{'App.HRServices.Promo' | translate}} -10%</h1>
                        <h1 class="bold">{{'App.HRServices.Promo2' | translate}} -5%</h1>
                        <h1 class="bold">{{'App.HRServices.Promo3' | translate}} -15%</h1>
                    </div>

                    <!-- Promotions -->
                    <div class="promotionsPosition">
                        <img [src]="'./assets/Images/promotions.webp'"
                            [ngClass]="{'mobileWidthHeightMegaphone': isMobile}" alt="HR services, hr agency, bpo, human resource, talent hunter,promotionsCSoft" width="200px"
                            height="200px" loading="lazy">
                    </div>
                </div>

                <div class="visibility-auto mt-50px whiteRectangle rectanglePos">
                    <h2 class="bold"> {{'App.HRServices.SupportedLanguages' | translate}}</h2>
                </div>

                <div class="visibility-auto mt-50px whiteRectangle rectanglePos">
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives1' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives1Text' | translate}}</p>
                    <br>
                    <br>
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives2' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives2Text' | translate}}</p>
                    <br>
                    <br>
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives3' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives3Text' | translate}}</p>
                    <br>
                    <br>
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives4' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives4Text' | translate}}</p>
                    <br>
                    <br>
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives5' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives5Text1' | translate}}</p>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives5Text2' | translate}}</p>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives5Text3' | translate}}</p>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives5Text4' | translate}}</p>
                    <br>
                    <br>
                    <h2 class="bold"> {{'App.HRServices.PositivesAndNegatives6' | translate}}</h2>
                    <p class="paragraphs paragraphs2">
                        {{'App.HRServices.PositivesAndNegatives6Text' | translate}}</p>
                    <div>
                        <button class="button shadow buttonMargin"
                            (click)="askForEmail()">{{'App.HRServices.SubscribeBonuses' | translate}}</button>
                        <app-modal-ask-email-subscribe></app-modal-ask-email-subscribe>
                    </div>
                    <br>
                </div>

                <div class="visibility-auto mt-50px">
                    <mat-accordion>
                        <!-- HR SERVICES -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.HRServices.HRServices' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.HRServices.HRServices' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs paragraphs2">+
                                                    {{'App.HRServices.StaffSelection' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    + {{'App.HRServices.SelectionOfExpertLevel' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    + {{'App.HRServices.SelectionManagement' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">+
                                                    {{'App.HRServices.SelectionIT' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>

                        <!-- Types of hiring -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.HRServices.TypesOfHiring' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.HRServices.TempStaff' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.LeasingOfStaff' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.ReducesTheCost' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.MeetsTheShort-term' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.FindsSolution' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.CompanyDoesNotPermision' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.ReduceNumberEmployees' | translate}}</p>
                                            </div>
                                            <br>
                                            <br>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.HRServices.PermanentStaff' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs">
                                                    {{'App.HRServices.YouWantHireRightEmployee' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.YouDonNotHaveTime' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.DonNotHesitate' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.WeHaveGroupHR' | translate}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                        <!-- Process of hiring -->
                        <div class="whiteRectangle rectanglePos">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="textBlackLink sizeMainText bold">
                                        {{'App.HRServices.ProcessOfHiring' | translate}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div>
                                    <div class="textBlackLink">
                                        <div>
                                            <div class="sizeOtherText colorBlack standartFont justyfive lineHeight">
                                                <h3 class="sizeMainText bold">
                                                    {{'App.HRServices.ProcessEmployeerHiring' | translate}}
                                                </h3>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Apply6Steps' | translate}}</p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.ThanksOf' | translate}}</p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step1' | translate}}</p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step2' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-1' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs3">
                                                    {{'App.HRServices.Step2-1-1' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs3">
                                                    {{'App.HRServices.Step2-1-2' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs3">
                                                    {{'App.HRServices.Step2-1-3' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-2' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-3' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-4' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-5' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-6' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step2-7' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step3' | translate}}</p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step4' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step4-1' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs3">
                                                    {{'App.HRServices.Step4-1-1' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs3">
                                                    {{'App.HRServices.Step4-1-2' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step4-2' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step5' | translate}}</p>
                                                <br>
                                                <p class="paragraphs paragraphs2">
                                                    {{'App.HRServices.Step5-1' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.Step6' | translate}}</p>
                                                <br>
                                                <p class="paragraphs">{{'App.HRServices.AroundTheWorld' | translate}}
                                                </p>
                                                <br>
                                                <p class="paragraphs">
                                                    {{'App.HRServices.SometimesFoundingOfTheRightCandidate' | translate}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>
                <!-- /////////// -->
                <br>
                <button class="button shadow rectanglePos"
                    routerLink="/contact">{{'App.HRServices.ContactUs' | translate}}</button>
            </div>
        </div>



    </div>
</div>

<br>
<br>
<br>