<div class="mt-180 whiteRectangle rectanglePos" [ngClass]="{'mt-180': isMobile, 'w320': isMobile}">
    <div class="positionLogo">
        <img [ngClass]="{'mobileLogoSize': isMobile}" [src]="'./assets/Images/logoTrans.png'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft"
            width="140px" height="120px" loading="lazy">
        <p class="bold sizeOtherTextBlack subHeaderAbout positionTextAbout standartFont justyfive">
            {{'App.Menu.Prices' | translate}}
        </p>
    </div>
    <p class="bold sizeOtherTextBlack subHeaderAbout positionTextAbout standartFont justyfive">
        {{'App.Prices.actualPrice1' | translate}}
    </p>

    <h2 class="sizeOtherTextBlack subHeaderAbout bold justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice2' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice3' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice4' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice5' | translate}}
    </h2>
    <br>
    <br>
    <p class="bold sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice6' | translate}}
    </p>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice7' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice8' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice9' | translate}}
    </h2>
    <h2 class="sizeOtherTextBlack subHeaderAbout justify textPositions standartFont justyfive">
        {{'App.Prices.actualPrice10' | translate}}
    </h2>

</div>