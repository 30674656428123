<div class="fullPrice">
    <title>{{'App.Home.Title' | translate}}</title>

    <div class="displayFlex mt-50px" [ngClass]="{'flexDirection': isMobile, 'mt-0': isMobile}">
        <div class="position videoAnimation">
            <!-- Rotate pictures  -->
            <!-- <img class="imgMargin HomePagePicAnimation" [src]="'./assets/Images/Prices.png'" alt="prices" width="200px"
                height="auto"> -->
            <video *ngIf="isChrome" autoplay muted loop controls="false" oncanplay="this.play()"
                onloadedmetadata="this.muted = true" class="contractVideo"
                [ngClass]="{'mobileContractVideo': isMobile}" poster="./assets/Images/priceImage.webp">
                <source src="./assets/Images/contract.mp4" type="video/mp4">
            </video>

            <img *ngIf="!isChrome" class="contractImage" [ngClass]="{'mobileContractImage': isMobile}"
                [src]="'./assets/Images/priceImage.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter,prices" loading="lazy">
        </div>
        <br>
        <!-- FONTS Cooper Black (Capital) and Ariel (small) -->
        <div class="positionText promoAnimation">

            <div class="offers sizeOtherText whiteRectangle rectanglePos" [ngClass]="{'mlAuto': isMobile}">
                <h1 class="bold">{{'App.HRServices.MinimalEmployee' | translate}}</h1>
                <h1 class="bold">{{'App.HRServices.Promo' | translate}} -10%</h1>
                <h1 class="bold">{{'App.HRServices.Promo2' | translate}} -5%</h1>
                <h1 class="bold">{{'App.HRServices.Promo3' | translate}} -15%</h1>
            </div>
        </div>
    </div>


    <!-- <div class="positionPricePage ml3vw whiteRectangle rectanglePos mt-50px">
        <h3 class="subHeaderAbout positionTextAbout HomePageMainTextAnimation2 standartFont justyfive lineHeight">
            {{'App.Prices.PartOfPrices' | translate}}
        </h3>
    </div> -->
    <div>
        <button class="button shadow buttonMargin"
            (click)="askForEmail()">{{'App.HRServices.OurPriceList' | translate}}</button>
        <app-modal-ask-email></app-modal-ask-email>
    </div>
    <div>
        <button class="button shadow buttonMargin"
            routerLink="/contact">{{'App.HRServices.ContactUs' | translate}}</button>
    </div>

</div>

<br>
<br>
<br>