<div class="fullAbout">
    <div class="positionAboutPage">

        <div class="margins animateText">
            <div class="contentMarginLeft sizeOtherText" [ngClass]="{'fontSize16': isMobile}">

                <div class="flexLogoandText">
                    <!-- Logo -->
                    <div class="logoPosition" [ngClass]="{'mobilelogoPosition': isMobile}">
                        <img [ngClass]="{'mobileLogoSize': isMobile}" [src]="'./assets/Images/logoTrans.png'"
                            alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" width="480px" height="420px" loading="lazy">
                        <h1 class="sizeHRSoftware" [ngClass]="{'pl-20': isMobile, 'mobileSizeHRSoftware': isMobile}">HR
                            and Software services</h1>
                    </div>

                    <div class="paddingTopRectangle" [ngClass]="{'mobilePaddingTopRectangle': isMobile}">
                        <div class="whiteRectangle standartFont justyfive lineHeight">
                            <div class="sizeMainText bold">
                                <h1 class="sizeMainText bold standartFont justyfive lineHeight">
                                    {{'App.About.AboutCSoft' | translate}}</h1>
                            </div>
                            {{'App.About.AboutText' | translate}}
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>

                <div class="flexQuestionandText" [ngClass]="{'mobileFlexQuestionandText': isMobile}">
                    <!-- Why Us CSoft -->
                    <div class="questionPosition" [ngClass]="{'mobileQuestionPosition': isMobile}">
                        <img [src]="'./assets/Images/WhyCSoft.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, whyCSoft"
                        [ngClass]="{'questionSize': isMobile}" width="480px" height="420px" loading="lazy">
                    </div>

                    <div class="whiteRectangle standartFont justyfive lineHeight"
                        [ngClass]="{'mobileWhiteRectangleQuestion': isMobile}">
                        <div class="visibility-auto">
                            <div class="sizeMainText bold standartFont justyfive lineHeight">
                                {{'App.About.WhyUs' | translate}}</div>
                            {{'App.About.WhyUsText' | translate}}
                        </div>
                        <div class="visibility-auto">{{'App.About.WhyUsText1' | translate}}</div>
                        <br>
                        <div class="visibility-auto">{{'App.About.WhyUsText2' | translate}}</div>
                        <br>
                        <div class="visibility-auto">{{'App.About.WhyUsText3' | translate}}</div>
                        <br>
                        <br>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot1' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot2' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot3' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot4' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot5' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot6' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot7' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot8' | translate}}</div>
                        <div class="visibility-auto"> {{'App.About.WhyUsDot9' | translate}}</div>
                    </div>
                </div>
                <br>
                <br>

                <!-- Charts -->
                <div class="whiteRectangle" [ngClass]="{'mobileWhiteRectangleQuestion': isMobile}">
                    <div class="standartFont justyfive lineHeight">
                        <div>
                            <div class="sizeMainText bold standartFont justyfive lineHeight">
                                {{'App.About.WhyUs' | translate}}</div>
                            {{'App.About.SeeTheGraphics' | translate}}
                        </div>

                    </div>
                    <div [ngClass]="{'chartsPosition': !isMobile, 'mobilechartsPosition': isMobile}">
                        <img [src]="'./assets/Images/hrchance.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, hrchance"
                        class="imgPosition mb-10px" [ngClass]="{'mobileChartsCSoft': isMobile}" width="600px" height="600px" loading="lazy">
                    </div>
                    <div [ngClass]="{'chartsPosition': !isMobile, 'mobilechartsPosition': isMobile}">
                        <img [src]="'./assets/Images/satisfyClients.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, satisfyClients"
                        class="imgPosition" [ngClass]="{'mobileChartsCSoft': isMobile}" width="600px" height="600px" loading="lazy">
                    </div>

                    <div class="standartFont justyfive lineHeight">
                        <div>                            
                            {{'App.About.OurExperience' | translate}}
                        </div>

                    </div>
                </div>
                <br>
                <br>
                <br>

                <!--Principles CSoft -->
                <div class="principlesPosition">
                    <img [src]="'./assets/Images/Principles.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, principlesCSoft"
                    class="imgPosition" [ngClass]="{'mobilePrinciplesCSoft': isMobile}" width="600px" height="600px" loading="lazy">
                </div>
                <div class="whiteRectangle">
                    <div class="visibility-auto sizeMainText bold">
                        {{'App.About.OurPrincipes' | translate}}
                    </div>
                    <br>

                    <div class="visibility-auto ThePrinciples">
                        <div class="theDots">
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot"></span>
                            <div [ngClass]="{'lineMobile': isMobile}" class="line"></div>
                            <span class="dot blue"></span>
                        </div>

                        <div class="theText">
                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Professionalism' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Confidentiality' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Equality' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Trust' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Respect' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Value' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.OpenCommunication' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Accountability' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Non-discrimination' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Mediation' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Flexibility' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.Short-term' | translate}}</h1>

                            <h1 [ngClass]="{'textHeightMobile': isMobile}" class="textHeight fontSize16">
                                {{'App.About.DocumentationAndAuthenticity' | translate}}</h1>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<br>
<br>
<br>