<div class="fullAbout">
    <h3 class="text sizeMainText animateHeader bold margins">{{'App.News.News' | translate}}</h3>
    <title class="text sizeMainText animateHeader bold margins">{{'App.News.News' | translate}}</title>

    <div class="margins marginTop animateText">
        <div class="contentMarginLeft sizeOtherText" [ngClass]="{'fontSize16': isMobile}">

            <div class="frame shadow" [ngClass]="{'expanded1': isExpanded}">
                <div class="position">
                    <img class="position" [src]="'./assets/Images/QA.jpg'" alt="HR services, hr agency, bpo, human resource, talent hunter,about" height="25%" width="100%" loading="lazy">
                </div>
                <div>
                    <div class="headerSize">
                        <p>{{'App.News.QuestionsAndAnswers' | translate}}</p>
                    </div>
                    <div class="newsPosition" [ngClass]="{'textExpanded1': isExpanded}">
                        <p>1. С какво се занимава вашата компания?</p>
                        <p>Компанията ни се занимава с две основни неща, а именно HR услуги основно в IT сферата и изработка на клиентски </p>
                        <p>софтуер</p>
                        <p>2. Целите на компанията през следващата година?</p>
                        <p>Целите на компанията ни са както за тази така и за следващите години да продължаваме да правим клиентите ни</p> 
                        <p>щастливи и доволни от нашите услуги.</p>
                        <p>3. Бихте ли разказали малко повече за вашият софтуерен продукт?</p>
                        <p>Софтуерният продукт на CSoft е платформа, която лесно може да бъде доработена за някой от следните сфери,</p> 
                        <p>а именно: Агро бизнес, Животновъдство, Винарски изби и винопроизводство, HR софтуер, както и много други.</p>
                        <p>4. На кои пазари се конкурирате?</p>
                        <p>CSoft се конкурира на пазари основно в САЩ и Европа</p>
                        <p>5. Какво бихте казали на бъдещите ви клиенти?</p>
                        <p>На всички бъдещи клиенти на CSoft бих казал да ни се доверят напълно. Екипът ни от софтуерни инженери </p>
                        <p>напълно ще отговори на очакванията Ви.</p>
                        <p>6. Защо един бъдещ клиент би избрал компанията ви?</p>
                        <p>Ние разполагаме със софтуерни специалисти които ще направят възможно най-добрия подбор за вас и вашия бизнес. </p>
                        <p>Ние ще отговорим на 100% на вашите очаквания. Доверете ни се.</p>
                    </div>

                    <div>
                        <div (click)="pannelExpander(1)" class="positionExpander collapsible">
                            {{'App.News.More' | translate}}
                            <svg class="svgAlighment" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256"
                                style="enable-background:new 0 0 256 256;"
                                [ngStyle]="{'transform': isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}"
                                xml:space="preserve" width="16" height="16">
                                <g>
                                    <g class="colorArrow">
                                        <polygon
                                            points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>

                </div>

            </div>

            <div class="frame shadow" [ngClass]="{'expanded2': isExpanded2}">
                <div class="position">
                    <img class="position" [src]="'./assets/Images/QA.jpg'" alt="HR services, hr agency, bpo, human resource, talent hunter,about" height="25%" width="100%" loading="lazy">
                </div>
                <div>
                    <div>
                        <p>TEXT ZAGLAVIE</p>
                    </div>
                    <div class="newsPosition" [ngClass]="{'textExpanded2': isExpanded2}">
                        <p>1. С какво се занимава вашата компания?</p>
                        <p>2. Целите на компанията през следващата година?</p>
                        <p>3. Бихте ли разказали малко повече за вашият софтуерен продукт?</p>
                        <p>4. На кои пазари се конкурирате?</p>
                        <p>5. Какви са целите на компанията?</p>
                        <p>6. Какво бихте казали на бъдещите ви клиенти?</p>
                        <p>7. Защо един бъдещ клиент би избрал компанията ви?</p>
                    </div>

                    <div >
                        <div (click)="pannelExpander(2)" class="positionExpander collapsible">
                            {{'App.News.More' | translate}}
                            <svg class="svgAlighment" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256"
                                style="enable-background:new 0 0 256 256;"
                                [ngStyle]="{'transform': isExpanded2 ? 'rotate(180deg)' : 'rotate(0deg)'}"
                                xml:space="preserve" width="16" height="16">
                                <g>
                                    <g class="colorArrow">
                                        <polygon
                                            points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>

                </div>
            </div>

            <div class="frame shadow">
                <div class="position">
                    <img class="position" [src]="'./assets/Images/QA.jpg'" alt="HR services, hr agency, bpo, human resource, talent hunter,about" height="25%" width="100%" loading="lazy">
                </div>
                <div>
                    <div>
                        <p>TEXT ZAGLAVIE</p>
                    </div>
                    <div>
                        <p>TEXT ZAGLAVIEBABAAB</p>
                    </div>

                    <div >
                        <div (click)="pannelExpander(3)" class="positionExpander collapsible">
                            {{'App.News.More' | translate}}
                            <svg class="svgAlighment" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256"
                                style="enable-background:new 0 0 256 256;"
                                [ngStyle]="{'transform': isExpanded3 ? 'rotate(180deg)' : 'rotate(0deg)'}"
                                xml:space="preserve" width="16" height="16">
                                <g>
                                    <g class="colorArrow">
                                        <polygon
                                            points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</div>
<br>
<br>
<br>