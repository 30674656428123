<div class="fullServices">
    <div class="marginsZero">
        <div [ngClass]="{'position': !isMobile}">
            <video *ngIf="isChrome" autoplay muted loop controls="false" oncanplay="this.play()"
                onloadedmetadata="this.muted = true" class="trainingVideo" [ngClass]="{'mobiletrainingVideo': isMobile}"
                poster="./assets/Images/trainingImage.webp">
                <source src="./assets/Images/training.mp4" type="video/mp4">
            </video>

            <img *ngIf="!isChrome" class="trainingImage" [ngClass]="{'mobileTrainingImage': isMobile}"
                [src]="'./assets/Images/trainingImage.webp'"
                alt="HR services, hr agency, bpo, human resource, talent hunter, trainingsServices" loading="lazy">
        </div>
        <title class="margins text sizeMainText animateHeader bold position">
            {{'App.Trainings.TrainingServices'| translate}}</title>

        <div class="contentMarginLeft sizeOtherText displayFlex position colorBlack whiteRectangle rectanglePos"
            [ngClass]="{'colorBlack': isMobile, 'mobileRectanglePos': isMobile}">
            <div class="TextAnimation standartFont justyfive lineHeight">
                <div>
                    <h1 class="sizeMainText bold">
                        {{'App.Trainings.PerfectStaff'| translate}}
                        <br>
                        {{'App.Trainings.CreateIt'| translate}}
                    </h1>

                    {{'App.Trainings.ThroughTrainingForSpecialists'| translate}}
                </div>

                <br>
                <div class="sizeOtherText" [ngClass]="{'h1000px': isMobile}">
                    <div class="sizeMainText bold">
                        {{'App.Trainings.Advantages'| translate}}
                    </div>

                    {{'App.Trainings.AcquisitionOfDevelopment'| translate}}
                    <br>
                    {{'App.Trainings.DevelopmentOfEmployees'| translate}}
                    <br>
                    {{'App.Trainings.PreparingStaff'| translate}}
                    <br>
                    {{'App.Trainings.ProvidingOpportunity'| translate}}
                    <br>
                    {{'App.Trainings.ImprovingThePerformance'| translate}}
                    <br>
                    {{'App.Trainings.IncreasingJobSatisfaction'| translate}}
                    <br>
                    {{'App.Trainings.EnablingTheDelegation'| translate}}
                </div>

                <br>
                <div>
                    <div>
                        <table id="trainingPrograms">
                            <tr>
                                <th> {{'App.Trainings.Seminars' | translate}}</th>
                                <th> {{'App.Menu.About' | translate}}</th>
                                <th> {{'App.SoftwareServices.TableCol3Header' | translate}}</th>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.Communication' | translate}} - 6 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutCommunication' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.Leadership' | translate}} - 8 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutLeadership' | translate}}</p>
                                </td>
                                <td>450 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.ManagingRemoteTeams' | translate}} - 3 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutManagingRemoteTeams' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.TimeManagement' | translate}} - 3 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutTimeManagement' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.EmotionalInteligent' | translate}} - N/A {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutEmotionalInteligent' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.Motivation' | translate}} - 3 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutMotivation' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.MentorshipPeopleDevelopment' | translate}} - 6-7 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutMentorshipPeopleDevelopment' | translate}}</p>
                                </td>
                                <td>400 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.LivePresentaionSkills' | translate}} - N/A {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutLivePresentaionSkills' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.OnlinePresentationSkills' | translate}} - N/A {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutOnlinePresentationSkills' | translate}}</p>
                                </td>
                                <td>200 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>
                            <tr>
                                <td><h3>{{'App.Trainings.ConflictManagement' | translate}} - 4 {{'App.Trainings.Hours' | translate}}</h3></td>
                                <td>
                                    <p>{{'App.Trainings.AboutConflictManagement' | translate}}</p>
                                </td>
                                <td>300 Euro {{'App.Trainings.PerPerson' | translate}}</td>
                            </tr>

                        </table>
                    </div>

                    <button class="button shadow"
                        routerLink="/contact">{{'App.HRServices.ContactUs' | translate}}</button>
                </div>

                <br>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>