import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalDialogService } from '../modal-dialog.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

  display$: Observable<'open' | 'close'>;

  constructor(private modalService: ModalDialogService) { }

  ngOnInit(): void {
    this.display$ = this.modalService.watch();
  }

  close() {
    localStorage.setItem('cookieMessage', 'exist');
    this.modalService.close();
  }
}
