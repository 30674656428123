
<h3 class="text sizeMainText animateHeader bold position margins textWhite">{{'App.Cookies.Cookies' | translate}}</h3>
<title class="text sizeMainText animateHeader bold position margins textWhite">{{'App.Cookies.Cookies' | translate}}</title>
<div class="margins marginTop animateText">
    <div class="contentMarginLeft sizeOtherText textWhite" [ngClass]="{'fontSize16': isMobile}">
        <p>{{'App.Cookies.CL1' | translate}}</p>
        <p>{{'App.Cookies.CL2' | translate}}</p>
        <p>{{'App.Cookies.CL3' | translate}}</p>
        <br>
        <p>{{'App.Cookies.CL4' | translate}}</p>
        <br>
        <p>{{'App.Cookies.CL5' | translate}}</p>
        <br>
        <p>{{'App.Cookies.CL6' | translate}}</p>
        <p>{{'App.Cookies.CL7' | translate}}</p>
        <br>
        <p>{{'App.Cookies.CL8' | translate}}</p>
        <p>{{'App.Cookies.CL9' | translate}}</p>
        <p>{{'App.Cookies.CL10' | translate}}</p>
        <br>
        <p>{{'App.Cookies.CL11' | translate}}</p>
        <p>{{'App.Cookies.CL12' | translate}}</p>
        <p>{{'App.Cookies.CL13' | translate}}</p>
        <br>
        <br>
        <br>
    </div>
</div>