<div class="fullContent justyfiPos" [ngClass]="{'displayFlex': !isMobile}">
    <div class="margins">
        <h3 class=" textWhite text sizeMainText animateHeader bold">{{'App.Contacts.Contacts' | translate}}</h3>
        <title class="text sizeMainText animateHeader bold">{{'App.Contacts.Contacts' | translate}}</title>

        <div class="animateText sizeOtherText">
            <div class="fullContent displayFlex contentMarginLeft" [ngClass]="{'mobilecontentMarginLeft': isMobile}">
                <div class="marginSVG">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="24px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 368 368"
                        style="enable-background:new 0 0 368 368;" xml:space="preserve">
                        <path style="fill:#CCE4FF;" d="M280,107.6c0,18.24-4.72,35.2-12.8,49.84L224.88,232l-18.64,32.96L184,304.16l-22.24-39.2L143.12,232
	l-42.24-74.56C92.64,142.8,88,125.84,88,107.6C88,52.56,130.96,8,184,8S280,52.56,280,107.6z M232,104c0-26.48-21.52-48-48-48
	s-48,21.52-48,48s21.52,48,48,48S232,130.48,232,104z" />
                        <g>
                            <path style="fill:#007AFF;" d="M64,312c0,31.928,51.592,56,120,56c68.416,0,120-24.072,120-56c0-25.464-34.32-46.736-84.84-53.624
		l55.048-97.072C283.232,144.952,288,126.384,288,107.6C288,48.272,241.344,0,184,0S80,48.272,80,107.6
		c0,19,4.808,37.592,13.92,53.784l54.92,96.992C98.32,265.264,64,286.528,64,312z M96,107.6C96,57.096,135.48,16,184,16
		s88,41.096,88,91.6c0,16.088-4.08,31.984-11.76,45.888l-61.072,107.696c-0.008,0.016-0.016,0.032-0.024,0.048L184,287.952
		l-15.28-26.936L107.848,153.52C100.096,139.736,96,123.864,96,107.6z M157.392,273.464l19.656,34.648
		c1.416,2.504,4.08,4.048,6.96,4.048c2.88,0,5.536-1.544,6.96-4.048l19.648-34.648C259.128,278.664,288,296.8,288,312
		c0,18.92-42.712,40-104,40S80,330.92,80,312C80,296.808,108.872,278.664,157.392,273.464z" />
                            <path style="fill:#007AFF;"
                                d="M240,104c0-30.88-25.128-56-56-56c-30.88,0-56,25.12-56,56s25.12,56,56,56
		C214.872,160,240,134.88,240,104z M144,104c0-22.056,17.944-40,40-40s40,17.944,40,40s-17.944,40-40,40S144,126.056,144,104z" />
                        </g>

                    </svg>

                </div>
                <div>
                    <p class="textWhite">{{'App.Contacts.Address' | translate}}</p>
                    <p class="wMaxContent textWhite">{{'App.Contacts.CountryCity' | translate}}</p>
                    <p class="wMaxContent textWhite">{{'App.Contacts.CountryCity2' | translate}}</p>
                    <p class="wMaxContent textWhite">{{'App.Contacts.CountryCity3' | translate}}</p>
                </div>
            </div>
            <br>
            <div class="fullContent displayFlex contentMarginLeft" [ngClass]="{'mobilecontentMarginLeft': isMobile}">
                <div class="marginSVG">
                    <svg height="24" viewBox="0 -28 480 480" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m8 40 232 144 232-144v-32h-464zm0 0" fill="#9bc9ff" />
                        <g fill="#1e81ce">
                            <path
                                d="m0 0v368h224v-16h-208v-297.601562l224 139.015624 224-139.015624v305.601562h16v-360zm240 174.585938-224-139.035157v-19.550781h448v19.550781zm0 0" />
                            <path
                                d="m344 216c-43.71875.003906-82.769531 27.347656-97.71875 68.433594-14.953125 41.082031-2.613281 87.128906 30.875 115.234375 33.488281 28.101562 80.980469 32.257812 118.84375 10.402343l-8-13.839843c-34.492188 19.914062-78.058594 14.179687-106.222656-13.980469-28.164063-28.160156-33.902344-71.726562-13.992188-106.21875s60.503906-51.316406 98.976563-41.015625c38.46875 10.300781 65.226562 45.15625 65.238281 84.984375v24c0 8.835938-7.164062 16-16 16s-16-7.164062-16-16v-72h-16v8.886719c-16.140625-16.542969-40.789062-21.46875-62.046875-12.40625-21.261719 9.066406-34.769531 30.265625-34.003906 53.363281.761719 23.101562 15.640625 43.359375 37.453125 51.003906 21.8125 7.640625 46.082031 1.09375 61.09375-16.480468 5.898437 14.046874 20.878906 21.984374 35.8125 18.984374 14.933594-3.003906 25.679687-16.117187 25.691406-31.351562v-24c-.066406-57.410156-46.589844-103.933594-104-104zm0 144c-22.089844 0-40-17.910156-40-40s17.910156-40 40-40 40 17.910156 40 40c-.027344 22.082031-17.917969 39.972656-40 40zm0 0" />
                        </g>
                    </svg>
                </div>
                <div>
                    <p class="textWhite">{{'App.Contacts.Email' | translate}}</p>
                    <p class="wMaxContent textWhite">
                        &#099;&#115;&#111;&#102;&#116;&#046;&#104;&#114;&#046;&#101;&#117;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
                    </p>
                    <p class="wMaxContent textWhite">
                        &#099;&#115;&#111;&#102;&#116;&#101;&#117;&#064;&#099;&#115;&#111;&#102;&#116;&#101;&#117;&#046;&#099;&#111;&#109;
                    </p>
                    <!--EMAIL-->
                </div>
            </div>
            <br>

            <div class="fullContent displayFlex contentMarginLeft" [ngClass]="{'mobilecontentMarginLeft': isMobile}">
                <div class="marginSVG">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="24px"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 503.604 503.604"
                        style="enable-background:new 0 0 503.604 503.604;" xml:space="preserve">
                        <g>
                            <g>
                                <path fill="#1a6fb0" d="M337.324,0H167.192c-28.924,0-53.5,23.584-53.5,52.5v398.664c0,28.916,24.056,52.44,52.98,52.44l170.412-0.184
			c28.92,0,52.58-23.528,52.58-52.448l0.248-398.5C389.908,23.452,366.364,0,337.324,0z M227.68,31.476h49.36
			c4.336,0,7.868,3.52,7.868,7.868c0,4.348-3.532,7.868-7.868,7.868h-49.36c-4.348,0-7.868-3.52-7.868-7.868
			C219.812,34.996,223.332,31.476,227.68,31.476z M198.02,33.98c2.916-2.912,8.224-2.952,11.136,0c1.46,1.456,2.324,3.5,2.324,5.588
			c0,2.048-0.864,4.088-2.324,5.548c-1.452,1.46-3.504,2.32-5.548,2.32c-2.084,0-4.088-0.86-5.588-2.32
			c-1.452-1.456-2.28-3.5-2.28-5.548C195.736,37.48,196.568,35.436,198.02,33.98z M250.772,488.008
			c-12.984,0-23.544-10.568-23.544-23.548c0-12.984,10.56-23.548,23.544-23.548s23.544,10.564,23.544,23.548
			C274.316,477.44,263.752,488.008,250.772,488.008z M365.488,424.908H141.232V74.756h224.256V424.908z" />
                            </g>
                        </g>

                    </svg>
                </div>
                <div>
                    <p class="textWhite">{{'App.Contacts.Tel' | translate}}</p>
                    <p class="wMaxContent textWhite"> +359 878 340 413</p>
                </div>
            </div>
            <br>

            <div class="fullContent displayFlex contentMarginLeft" [ngClass]="{'mobilecontentMarginLeft': isMobile}">
                <div class="marginSVG">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" viewBox="0 0 512 512"
                        style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <circle fill="#9bc9ff" cx="386" cy="210" r="20" />
                                    <path fill="#1a6fb0" d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
                               c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
                               c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
                               c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
                               v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
                               c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z" />
                                    <path fill="#1a6fb0"
                                        d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
                               c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z" />
                                    <path fill="#1a6fb0" d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
                               c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z" />
                                    <circle fill="#9bc9ff" cx="299" cy="210" r="20" />
                                    <circle fill="#9bc9ff" cx="212" cy="297" r="20" />
                                    <circle fill="#9bc9ff" cx="125" cy="210" r="20" />
                                    <circle fill="#9bc9ff" cx="125" cy="297" r="20" />
                                    <circle fill="#9bc9ff" cx="125" cy="384" r="20" />
                                    <circle fill="#9bc9ff" cx="212" cy="384" r="20" />
                                    <circle fill="#9bc9ff" cx="212" cy="210" r="20" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div>
                    <p class="textWhite">{{'App.Contacts.WorkTime' | translate}}</p>
                    <p class="wMaxContent textWhite">{{'App.Contacts.MonFri' | translate}}</p>
                    <p class="wMaxContent textWhite">09:00 - 17:00</p>
                </div>
            </div>
            <br>
            <!-- <div class="textWhite">
                Association of
                <div>
                    <a href="https://itcompanies.net/users/149">
                    <img [src]="'./assets/Images/ITnetworkingLogo.png'" alt="itNetworking" width="162px" height="52px">
                    </a>
                </div>
            </div> -->

        </div>
    </div>



    <div class="ContactForm">
        <form [formGroup]="form">
            <div>
                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Name' | translate}}</label>
                    <input class="input shadow" id="name" formControlName="contactName" required type="text"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['contactName'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Tel' | translate}}</label>
                    <input class="input shadow" id="tel" formControlName="contactTel" required type="text"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['contactTel'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Email' | translate}}</label>
                    <input class="input shadow" id="email" formControlName="contactEmail" required type="email"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['contactEmail'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Company' | translate}}</label>
                    <input class="input shadow" id="company" formControlName="contactCompany" required type="text"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['contactCompany'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>


                <!-- Friend, Social media, web ad, google, other -->
                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.HowDidYouHear' | translate}}</label>
                    <select id="howDidYouHear" formControlName="contactHowDidYouHear" class="dropdown shadow"
                        (change)="selectedValue($event)" required>
                        <option value="Friend">Friend</option>
                        <option value="SocMedia">Social media</option>
                        <option value="WEBadv">Web adv</option>
                        <option value="Google">Google</option>
                        <option value="Other">Other</option>
                    </select>
                    <label class="redText"
                        *ngIf="form.controls['contactHowDidYouHear'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Subject' | translate}}</label>
                    <input class="input shadow" id="subject" formControlName="contactSubject" required type="text"
                        size="50px" />
                    <label class="redText"
                        *ngIf="form.controls['contactSubject'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts textBoxes">
                    <label>{{'App.Contacts.Text' | translate}}</label>
                    <textarea id="message" formControlName="contactText" required class="textarea shadow" type="text"
                        aria-multiline="true" [ngClass]="{'w350px': isMobile}" rows="18" cols="85" #filterName
                        name="filterName"></textarea>
                    <label class="redText"
                        *ngIf="form.controls['contactText'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>
                </div>

                <div class="textContacts checkbox">
                    <input formControlName="personalData" required class="shadow" type="checkbox" id="personalData">
                    <label>{{'App.Contacts.PersonalData' | translate}}</label>
                    <label class="redText"
                        *ngIf="form.controls['personalData'].hasError('empty')">{{'App.Contacts.Required' | translate}}</label>

                </div>

                <div class="fullContent" [ngClass]="{'displayFlex': !isMobile}">
                    <div class="textContacts textBoxes betweenButtons">
                        <button id="btnSend" class="button shadow" type="submit"
                            (click)="send()">{{'App.Contacts.Send' | translate}}</button>
                    </div>

                    <div class="textContacts textBoxes betweenButtons">
                        <button id="btnClear" class="button shadow"
                            (click)="clearText()">{{'App.Contacts.Clear' | translate}}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="allOffices">
        <div class="positionMap animateMap shadow" [ngClass]="{'w20Percent': !isMobile}">
            <agm-map id="map" class="mapSize" [latitude]="42.65186" [longitude]="23.38407" (mapClick)="OnClick($event)">
                <!-- Biz Hub zad Telerik str. Anna Ahmatova 9 -->
                <agm-marker [latitude]="42.65186" [longitude]="23.38407"></agm-marker>
            </agm-map>
        </div>

        <div class="positionMap animateMap shadow" [ngClass]="{'w20Percent': !isMobile}">
            <agm-map id="map" class="mapSize" [latitude]="37.773344" [longitude]="-122.418578"
                (mapClick)="OnClick($event)">
                <!-- Biz Hub zad Telerik str. Anna Ahmatova 9 -->
                <agm-marker [latitude]="37.773344" [longitude]="-122.418578"></agm-marker>
            </agm-map>
        </div>

        <div class="positionMap animateMap shadow" [ngClass]="{'w20Percent': !isMobile}">
            <agm-map id="map" class="mapSize" [latitude]="46.1984162" [longitude]="6.1386685"
                (mapClick)="OnClick($event)">
                <!-- Biz Hub zad Telerik str. Anna Ahmatova 9 -->
                <agm-marker [latitude]="46.1984162" [longitude]="6.1386685"></agm-marker>
            </agm-map>
        </div>
    </div>
</div>

<br>
<br>
<br>