<div>
    <title>HR and software services Menu</title>
    <menu class="position">
        <div class="scrollmenu sticky" [ngClass]="{'mobileScrollmenu': isMobile}" matRipple>
            <div class="menuPosition MenuAnimation">

                <div *ngIf="isMobile" class="dropdown" style="float:left;">
                    <button class="dropbtn" (click)="mobileMenuClick()">|||</button>
                    <div [ngClass]="{'dropdown-content': isMenuOpened}" style="left:0;">
                        <a (click)="mobileMenuClick()" routerLink="/home"
                            class="menuText">{{'App.Menu.Home' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/about"
                            class="menuText">{{'App.Menu.About' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/services"
                            class="menuText">{{'App.Menu.Services' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/prices"
                            class="menuText">{{'App.Menu.Prices' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/clients"
                            class="menuText">{{'App.Menu.Clients' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/jobs"
                            class="menuText">{{'App.Menu.Jobs' | translate}}</a>
                        <a (click)="mobileMenuClick()" routerLink="/contact"
                            class="menuText">{{'App.Menu.Contact' | translate}}</a>
                    </div>
                </div>

                <div *ngIf="!isMobile">
                    <span class="spaceBetweenMenus">
                        <svg height="24" viewBox="0 0 480.01663 480" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m63.96875 472.015625h128v-144h96v144h128v-240h-352zm0 0" fill="#9bc9ff" />
                            <path d="m7.96875 232.015625h464l-232-224zm0 0" fill="#9bc9ff" />
                            <path
                                d="m477.566406 226.257812-232-224c-3.097656-3-8.015625-3-11.117187 0l-232 224c-2.347657 2.257813-3.085938 5.714844-1.875 8.738282 1.214843 3.019531 4.136719 5.007812 7.394531 5.019531h48v232c0 4.417969 3.582031 8 8 8h128c4.417969 0 8-3.582031 8-8v-136h80v136c0 4.417969 3.582031 8 8 8h128c4.417969 0 8-3.582031 8-8v-232h48c3.269531.019531 6.222656-1.953125 7.457031-4.984375 1.234375-3.027344.496094-6.503906-1.859375-8.773438zm-69.597656 237.757813h-112v-136c0-4.417969-3.582031-8-8-8h-96c-4.417969 0-8 3.582031-8 8v136h-112v-224h336zm-380.191406-240 212.191406-204.878906 212.191406 204.878906zm0 0"
                                fill="#1e81ce" /></svg>
                        <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Home' | translate}}</a>
                    </span>
                    <!-- <span>
                <svg height="24" viewBox="0 0 64 64" width="24" xmlns="http://www.w3.org/2000/svg">
                    <g fill="#bddbff">
                        <path
                            d="m58 13v24a1.766 1.766 0 0 1 -.07.52 2.98 2.98 0 0 0 -1.53.13l-3.71 1.35h-7.57a2.972 2.972 0 0 0 -1.65-.89l-10.05-1.77a19.975 19.975 0 0 0 -9.31.57l-.15.04c-.63.19-1.25.42-1.86.67a1.792 1.792 0 0 1 -.1-.62v-24h14v6l4-4 4 4v-6z" />
                        <path d="m58 9v4h-14v-6h12a2.006 2.006 0 0 1 2 2z" />
                        <path d="m36 7v6h-14v-4a2.006 2.006 0 0 1 2-2z" />
                        <path d="m21.83 53.12 1.15 1.64-9.83 6.88-10.33-14.75 9.83-6.88 1.72 2.46z" />
                    </g>
                    <path
                        d="m61.18 39.1a3.969 3.969 0 0 0 -2.18-2.29v-27.81a3.009 3.009 0 0 0 -3-3h-32a3.009 3.009 0 0 0 -3 3v28.03a20.264 20.264 0 0 0 -3.24 1.85l-3.14 2.2-1.15-1.64a1.007 1.007 0 0 0 -1.39-.25l-9.83 6.88a1.017 1.017 0 0 0 -.25 1.4l10.33 14.74a1 1 0 0 0 1.39.25l9.83-6.88a1.019 1.019 0 0 0 .25-1.4l-.57-.81a8.9 8.9 0 0 1 6.6-1.41l1.78.3a13 13 0 0 0 6.71-.58l20.47-7.45a4 4 0 0 0 2.39-5.13zm-16.18-31.1h11a1 1 0 0 1 1 1v3h-12zm-8 0h6v8.59l-2.29-2.3a1.008 1.008 0 0 0 -1.42 0l-2.29 2.3zm-14 1a1 1 0 0 1 1-1h11v4h-12zm0 5h12v5a.987.987 0 0 0 .62.92 1 1 0 0 0 1.09-.21l3.29-3.3 3.29 3.3a1.007 1.007 0 0 0 .71.29.838.838 0 0 0 .38-.08.987.987 0 0 0 .62-.92v-5h12v22.51a3.847 3.847 0 0 0 -.94.2l-3.54 1.29h-7.03a3.873 3.873 0 0 0 -1.85-.87l-10.05-1.77a20.993 20.993 0 0 0 -9.74.58l-.18.06c-.23.06-.45.15-.67.23zm-9.61 46.25-9.17-13.11 8.19-5.74 9.17 13.11zm46.01-19.43a2 2 0 0 1 -1.29 1.53l-20.47 7.45a10.988 10.988 0 0 1 -5.68.49l-1.79-.3a10.883 10.883 0 0 0 -8.09 1.74l-6.31-9.02 3.14-2.19a18.6 18.6 0 0 1 5.31-2.6l.18-.05a19.244 19.244 0 0 1 8.85-.55l10.04 1.77a2.048 2.048 0 0 1 1.3.83 2.012 2.012 0 0 1 .33.86 1.768 1.768 0 0 1 -.05.84 2.009 2.009 0 0 1 -2.29 1.42l-10.81-1.91-.34 1.96 10.8 1.91a3.419 3.419 0 0 0 .71.07 4.017 4.017 0 0 0 3.84-2.85l9.96-3.63a2 2 0 0 1 2.56 1.2 1.9 1.9 0 0 1 .1 1.03z"
                        fill="#3d9ae2" />
                </svg>
                <a routerLink="/products" class="menuText"> Products</a>
            </span> -->
                    <span class="spaceBetweenMenus">
                        <svg height="24" viewBox="0 0 480.008 480" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m256.007812 8.003906c-75.324218-.019531-145.210937 39.210938-184.425781 103.523438-39.214843 64.308594-42.082031 144.40625-7.574219 211.355468l-56 149.121094 149.121094-56c79.605469 41.027344 176.273438 28.675782 243.007813-31.050781 66.730469-59.726563 89.683593-154.4375 57.699219-238.089844-31.984376-83.652343-112.269532-138.890625-201.828126-138.859375zm16 376h-32v-240h32zm-16-272c-13.253906 0-24-10.746094-24-24s10.746094-24 24-24c13.253907 0 24 10.746094 24 24s-10.746093 24-24 24zm0 0"
                                fill="#9bc9ff" />
                            <g fill="#1e81ce">
                                <path
                                    d="m272.007812 392.003906h-32c-4.417968 0-8-3.582031-8-8v-240c0-4.417968 3.582032-8 8-8h32c4.417969 0 8 3.582032 8 8v240c0 4.417969-3.582031 8-8 8zm-24-16h16v-224h-16zm0 0" />
                                <path
                                    d="m256.007812 120.003906c-17.671874 0-32-14.328125-32-32s14.328126-32 32-32c17.671876 0 32 14.328125 32 32s-14.328124 32-32 32zm0-48c-8.835937 0-16 7.164063-16 16 0 8.835938 7.164063 16 16 16 8.835938 0 16-7.164062 16-16 0-8.835937-7.164062-16-16-16zm0 0" />
                                <path
                                    d="m8.007812 480.003906c-2.625.003906-5.085937-1.285156-6.582031-3.441406-1.4960935-2.160156-1.839843-4.917969-.914062-7.375l54.769531-145.816406c-15.421875-30.847656-23.390625-64.878906-23.273438-99.367188 0-123.710937 100.289063-223.99999975 224-223.99999975 123.710938 0 224 100.28906275 224 223.99999975 0 123.710938-100.289062 224-224 224-34.488281.121094-68.519531-7.851562-99.367187-23.273437l-145.816406 54.761719c-.902344.339843-1.855469.511718-2.816407.511718zm248-464c-114.820312.128906-207.871093 93.179688-208 208-.121093 33.136719 7.808594 65.804688 23.105469 95.199219 1.042969 2.011719 1.1875 4.375.390625 6.496094l-49.808594 132.625 132.617188-49.808594c2.125-.800781 4.488281-.660156 6.503906.382813 29.390625 15.296874 62.058594 23.222656 95.191406 23.105468 114.875 0 208-93.125 208-208s-93.125-208-208-208zm0 0" />
                            </g>
                        </svg>
                        <a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.About' | translate}}</a>
                    </span>

                    <span class="spaceBetweenMenus">
                        <svg id="Layer_3" enable-background="new 0 0 64 64" height="24" viewBox="0 0 64 64" width="24"
                            xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path
                                    d="m6 22-1-3h-3v-6h3l1-3-2-2 4-4 2 2 3-1v-3h6v3l3 1 2-2 4 4-2 2 1 3h3v6h-3l-1 3 2 2-4 4-2-2-3 1v3h-6v-3l-3-1-2 2-4-4z"
                                    fill="#666" />
                                <circle cx="16" cy="16" fill="#9bc9ff" r="8" />
                                <path
                                    d="m29 40-2 2 3 3 2-2 2 1v2h4v-2l2-1 2 2 3-3-2-2 1-2h2v-4h-2l-1-2 2-2-3-3-2 2-2-1v-2h-4v2l-2 1-2-2-3 3 2 2-1 2h-2v4h2z"
                                    fill="#666" />
                                <circle cx="36" cy="36" fill="#9bc9ff" r="5" />
                                <path
                                    d="m5 48-2 2 3 3 2-2 2 1v2h4v-2l2-1 2 2 3-3-2-2 1-2h2v-4h-2l-1-2 2-2-3-3-2 2-2-1v-2h-4v2l-2 1-2-2-3 3 2 2-1 2h-2v4h2z"
                                    fill="#666" />
                                <circle cx="12" cy="44" fill="#9bc9ff" r="5" />
                                <path
                                    d="m47.906 20.134c-3.481-1.55-5.906-5.018-5.906-9.046 0-4.065 2.468-7.558 6-9.088v.001 5.122l2 2.877h4l2-2.877v-5.122-.001c3.532 1.53 6 5.023 6 9.088 0 4.029-2.425 7.496-5.907 9.047"
                                    fill="#9bc9ff" />
                                <path
                                    d="m47.907 43.865c-3.482 1.551-5.907 5.018-5.907 9.047 0 4.065 2.468 7.558 6 9.088v-.001-5.122l2-2.877h4l2 2.877v5.122.001c3.532-1.53 6-5.023 6-9.088 0-4.029-2.425-7.497-5.907-9.047"
                                    fill="#9bc9ff" />
                                <path
                                    d="m47 48 .06-.239c1.288-5.155 1.94-10.448 1.94-15.761 0-5.313-.652-10.606-1.94-15.761l-.06-.239h10l-.06.239c-1.288 5.154-1.94 10.448-1.94 15.761 0 5.313.652 10.606 1.94 15.761l.06.239z"
                                    fill="#9bc9ff" />
                                <g>
                                    <path d="m51 18.999h2v1.999h-2z" fill="#9bc9ff" />
                                </g>
                                <g>
                                    <path d="m51 42.999h2v1.999h-2z" fill="#9bc9ff" />
                                </g>
                                <path fill="#1e81ce"
                                    d="m28.707 23.291-1.563-1.562.577-1.73h2.279c.553 0 1-.447 1-1v-5.999c0-.553-.447-1-1-1h-2.279l-.577-1.73 1.563-1.562c.39-.391.39-1.024 0-1.415l-4-4c-.389-.39-1.022-.39-1.415 0l-1.563 1.563-1.729-.579v-2.278c0-.553-.447-1-1-1h-6c-.553 0-1 .447-1 1v2.278l-1.73.577-1.563-1.562c-.187-.188-.442-.293-.707-.293 0-.001 0 0 0 0-.265 0-.52.104-.707.292l-4 4c-.39.391-.39 1.024 0 1.414l1.563 1.563-.577 1.73h-2.279c0-.001 0-.001 0-.001-.265 0-.519.105-.707.293-.188.189-.293.443-.293.709v5.999c0 .553.447 1 1 1l2.279.001.577 1.729-1.563 1.562c-.39.391-.39 1.024 0 1.415l4 4c.375.376 1.04.377 1.415-.001l1.562-1.562 1.73.578v2.278c0 .553.447 1 1 1h6c.553 0 1-.447 1-1v-2.278l1.73-.577 1.563 1.563c.392.392 1.025.392 1.415 0l4-4c.389-.391.389-1.024-.001-1.415zm-3.415-.585 1.293 1.292-2.585 2.586-1.292-1.293c-.268-.268-.662-.357-1.023-.241l-3 1c-.41.136-.685.518-.685.948v2h-4v-2c0-.43-.276-.812-.684-.948l-3-1c-.359-.117-.755-.027-1.023.242l-1.293 1.292-2.585-2.586 1.293-1.292c.268-.269.361-.664.241-1.023l-1-2.999c-.137-.409-.519-.684-.949-.684l-2-.001v-4l2 .001c.431-.001.813-.276.949-.685l1-3.001c.12-.359.026-.755-.242-1.022l-1.293-1.293 2.586-2.586 1.292 1.292c.268.269.666.363 1.023.242l3-1c.409-.135.685-.518.685-.948v-2h4v2c0 .43.276.812.684.948l3 1c.361.12.756.025 1.023-.241l1.293-1.293 2.586 2.586-1.293 1.292c-.268.269-.361.664-.241 1.023l1 3c.136.408.518.685.948.685h2v3.999h-2c-.43 0-.812.276-.948.685l-1 3c-.121.359-.027.755.24 1.023z" />
                                <path fill="#1e81ce"
                                    d="m16 6.999c-4.963 0-9 4.037-9 9 0 4.962 4.037 8.999 9 8.999 4.962 0 9-4.037 9-8.999 0-4.963-4.038-9-9-9zm0 15.999c-3.86 0-7-3.14-7-6.999 0-3.86 3.14-7 7-7 3.859 0 7 3.14 7 7 0 3.859-3.141 6.999-7 6.999z" />
                                <path fill="#1e81ce"
                                    d="m42.707 45.706 3-3c.39-.391.39-1.024 0-1.415l-1.491-1.49.401-.803h1.383c.553 0 1-.447 1-1v-3.999c0-.553-.447-1-1-1h-1.382l-.401-.803 1.491-1.49c.39-.391.39-1.024 0-1.415l-3-3c-.389-.39-1.022-.39-1.415 0l-1.49 1.491-.803-.401v-1.382c0-.553-.447-1-1-1h-4c-.553 0-1 .447-1 1v1.382l-.803.401-1.49-1.491c-.39-.39-1.023-.39-1.415 0l-3 3c-.39.391-.39 1.024 0 1.415l1.491 1.49-.401.803h-1.382c-.553 0-1 .447-1 1v3.999c0 .553.447 1 1 1h1.382l.401.803-1.491 1.49c-.39.391-.39 1.024 0 1.415l3 3c.391.391 1.024.393 1.415 0l1.49-1.491.803.401v1.382c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-1.382l.803-.401 1.49 1.491c.392.393 1.025.391 1.414 0zm-3.154-3.602-2 1c-.339.169-.553.516-.553.894v1h-2v-1c0-.378-.214-.725-.553-.894l-2-1c-.383-.192-.849-.117-1.154.187l-1.293 1.293-1.586-1.586 1.293-1.292c.304-.305.38-.77.187-1.154l-1-2c-.169-.339-.516-.554-.894-.554h-1v-1.999h1c.378 0 .725-.215.894-.554l1-2c.193-.385.117-.85-.187-1.154l-1.293-1.292 1.586-1.586 1.292 1.293c.306.304.771.382 1.154.187l2-1c.34-.169.554-.516.554-.894v-1h2v1c0 .378.214.725.553.894l2 1c.386.195.85.117 1.154-.187l1.293-1.293 1.586 1.586-1.293 1.292c-.304.305-.38.77-.187 1.154l1 2c.169.339.516.554.894.554h1v1.999h-1c-.378 0-.725.215-.894.554l-1 2c-.193.385-.117.85.187 1.154l1.293 1.292-1.586 1.586-1.292-1.293c-.306-.304-.769-.379-1.155-.187z" />
                                <path fill="#1e81ce"
                                    d="m36 29.999c-3.309 0-6 2.691-6 6 0 3.308 2.691 5.999 6 5.999 3.308 0 6-2.691 6-5.999 0-3.309-2.692-6-6-6zm0 9.999c-2.206 0-4-1.794-4-3.999 0-2.206 1.794-4 4-4s4 1.794 4 4c0 2.205-1.794 3.999-4 3.999z" />
                                <path fill="#1e81ce"
                                    d="m22 40.999h-1.382l-.401-.803 1.491-1.49c.39-.391.39-1.024 0-1.415l-3-3c-.389-.39-1.022-.39-1.415 0l-1.49 1.491-.803-.401v-1.382c0-.553-.447-1-1-1h-4c-.553 0-1 .447-1 1v1.382l-.803.401-1.49-1.491c-.39-.39-1.023-.39-1.415 0l-3 3c-.39.391-.39 1.024 0 1.415l1.491 1.49-.401.803h-1.382c-.553 0-1 .447-1 1v3.999c0 .553.447 1 1 1h1.382l.401.803-1.491 1.49c-.39.391-.39 1.024 0 1.415l3 3c.391.392 1.024.393 1.415 0l1.49-1.491.803.401v1.382c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-1.382l.803-.401 1.49 1.491c.392.393 1.025.392 1.415 0l3-3c.39-.391.39-1.024 0-1.415l-1.491-1.49.401-.803h1.382c.553 0 1-.447 1-1v-3.999c0-.553-.448-1-1-1zm-1 3.999h-1c-.378 0-.725.215-.894.554l-1 2c-.193.385-.117.85.187 1.154l1.293 1.292-1.586 1.586-1.292-1.293c-.305-.303-.769-.378-1.154-.187l-2 1c-.34.169-.554.516-.554.894v1h-2v-1c0-.378-.214-.725-.553-.894l-2-1c-.383-.191-.848-.116-1.154.187l-1.293 1.293-1.586-1.586 1.293-1.292c.304-.305.38-.77.187-1.154l-1-2c-.169-.339-.516-.554-.894-.554h-1v-1.999h1c.378 0 .725-.215.894-.554l1-2c.193-.385.117-.85-.187-1.154l-1.293-1.292 1.586-1.586 1.292 1.293c.306.304.771.381 1.154.187l2-1c.34-.169.554-.516.554-.894v-1h2v1c0 .378.214.725.553.894l2 1c.386.194.85.117 1.154-.187l1.293-1.293 1.586 1.586-1.293 1.292c-.304.305-.38.77-.187 1.154l1 2c.169.339.516.554.894.554h1z" />
                                <path fill="#1e81ce"
                                    d="m12 37.999c-3.309 0-6 2.691-6 6 0 3.308 2.691 5.999 6 5.999 3.308 0 6-2.691 6-5.999 0-3.309-2.692-6-6-6zm0 9.999c-2.206 0-4-1.794-4-3.999 0-2.206 1.794-4 4-4s4 1.794 4 4c0 2.205-1.794 3.999-4 3.999z" />
                                <path fill="#1e81ce"
                                    d="m56.992 20.806c3.665-1.856 6.008-5.61 6.008-9.719 0-4.341-2.591-8.269-6.603-10.005-.307-.135-.665-.104-.946.082-.282.185-.451.499-.451.835v4.81l-1.522 2.19h-2.955l-1.523-2.191v-4.809c0-.336-.169-.65-.451-.835-.281-.186-.636-.216-.946-.082-4.012 1.736-6.603 5.663-6.603 10.005 0 4.109 2.342 7.863 6.007 9.719.656 3.692.992 7.441.992 11.194 0 3.752-.337 7.5-.992 11.193-3.665 1.855-6.007 5.608-6.007 9.719 0 4.341 2.591 8.268 6.603 10.004.311.137.666.104.946-.082.281-.185.451-.499.451-.836v-4.809l1.522-2.191h2.955l1.523 2.191v4.809c0 .337.17.651.451.836.166.109.357.165.549.165.135 0 .27-.027.397-.083 4.011-1.736 6.603-5.664 6.603-10.004 0-4.111-2.342-7.864-6.007-9.719-.656-3.693-.993-7.441-.993-11.194s.337-7.501.992-11.193zm-6.993 11.193c0-5.048-.581-10.089-1.727-15h7.455c-1.146 4.912-1.727 9.953-1.727 15.001 0 5.046.581 10.086 1.728 14.999h-7.455c1.145-4.913 1.726-9.954 1.726-15zm-6.999-20.912c0-3.011 1.529-5.778 4-7.413v3.447c0 .204.062.403.179.571l2 2.877c.187.269.494.43.821.43h4c.328 0 .634-.161.821-.43l2-2.877c.117-.168.179-.367.179-.571v-3.447c2.471 1.636 4 4.402 4 7.413 0 2.773-1.32 5.335-3.466 7.003.12-.537.242-1.074.376-1.608l.06-.239c.075-.299.008-.616-.182-.858-.189-.243-.48-.385-.789-.385h-9.999c-.308 0-.599.142-.788.384s-.257.559-.183.857l.06.24c.134.534.256 1.071.376 1.609-2.145-1.668-3.465-4.231-3.465-7.003zm18 41.825c0 3.01-1.529 5.777-4 7.412v-3.448c0-.203-.062-.402-.179-.57l-2-2.878c-.187-.269-.494-.43-.821-.43h-4c-.328 0-.634.161-.821.43l-2 2.878c-.117.168-.179.367-.179.57v3.448c-2.471-1.635-4-4.402-4-7.412 0-2.774 1.32-5.337 3.465-7.004-.12.538-.242 1.075-.376 1.609l-.06.238c-.075.299-.008.615.182.858.189.242.48.384.788.384l10.001.003c.308 0 .599-.143.789-.386.189-.242.257-.56.182-.858l-.06-.238c-.134-.535-.256-1.072-.376-1.609 2.144 1.666 3.465 4.229 3.465 7.003z" />
                                <path fill="#1e81ce" d="m51 18.999h2v1.999h-2z" />
                                <path fill="#1e81ce" d="m51 42.999h2v1.999h-2z" />
                            </g>
                        </svg>
                        <a routerLink="/services" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Services' | translate}}</a>
                    </span>
                    <!-- <span>
                        <svg height="24" viewBox="-48 0 480 480" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m40 8v40h240v64h64v320h32v-360l-64-64zm0 0" fill="#78b9eb" />
                            <path d="m40 272h80v168h-80zm0 0" fill="#78b9eb" />
                            <path d="m40 80h208v88h-208zm0 0" fill="#78b9eb" />
                            <g fill="#1a6fb0">
                                <path
                                    d="m381.65625 66.34375-64-64c-1.5-1.5-3.535156-2.34375-5.65625-2.34375h-272c-4.417969 0-8 3.582031-8 8v32h-24c-4.417969 0-8 3.582031-8 8v424c0 4.417969 3.582031 8 8 8h336c4.417969 0 8-3.582031 8-8v-32h24c4.417969 0 8-3.582031 8-8v-360c0-2.121094-.84375-4.15625-2.34375-5.65625zm-365.65625 397.65625v-408h256v56c0 4.417969 3.582031 8 8 8h56v344zm272-360v-36.6875l36.6875 36.6875zm80 320h-16v-312h-.070312c.023437-2.113281-.792969-4.148438-2.273438-5.65625l-64-64c-1.511719-1.472656-3.546875-2.289062-5.65625-2.273438v-.070312h-232v-24h260.6875l59.3125 59.3125zm0 0" />
                                <path
                                    d="m40 176h208c4.417969 0 8-3.582031 8-8v-88c0-4.417969-3.582031-8-8-8h-208c-4.417969 0-8 3.582031-8 8v88c0 4.417969 3.582031 8 8 8zm8-88h192v72h-192zm0 0" />
                                <path d="m32 184h280v16h-280zm0 0" />
                                <path d="m32 208h280v16h-280zm0 0" />
                                <path d="m32 232h280v16h-280zm0 0" />
                                <path d="m144 384h168v16h-168zm0 0" />
                                <path d="m144 408h168v16h-168zm0 0" />
                                <path d="m144 432h168v16h-168zm0 0" />
                                <path d="m144 312h168v16h-168zm0 0" />
                                <path d="m144 336h168v16h-168zm0 0" />
                                <path d="m144 360h168v16h-168zm0 0" />
                                <path d="m144 264h168v16h-168zm0 0" />
                                <path d="m144 288h168v16h-168zm0 0" />
                                <path
                                    d="m120 264h-80c-4.417969 0-8 3.582031-8 8v168c0 4.417969 3.582031 8 8 8h80c4.417969 0 8-3.582031 8-8v-168c0-4.417969-3.582031-8-8-8zm-8 168h-64v-152h64zm0 0" />
                            </g>
                        </svg>
                        <a routerLink="/news" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="menuText">{{'App.Menu.News' | translate}}</a>
                    </span> -->
                    <span class="spaceBetweenMenus">
                        <svg height="24" viewBox="0 -28 480 480" width="24" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <g>
                                    <circle style="fill:#9bc9ff;" cx="166.658" cy="164.129" r="137.1" />
                                    <path fill="#1e81ce" d="M490.358,377.629c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.6-0.2-0.9s-0.1-0.6-0.2-0.8c-0.1-0.3-0.2-0.5-0.4-0.8
			c-0.1-0.3-0.2-0.5-0.4-0.8c-0.1-0.3-0.3-0.5-0.5-0.7s-0.3-0.5-0.5-0.7s-0.4-0.4-0.7-0.6c-0.2-0.2-0.4-0.4-0.6-0.6
			s-0.5-0.3-0.8-0.5c-0.2-0.1-0.4-0.3-0.7-0.4l-110.3-54.4c-0.3-0.2-0.6-0.3-1-0.4c-1.9-0.7-47.4-16.6-84.6,0.1l-82.1,29.7
			c-0.2,0.1-0.5,0.2-0.7,0.3c-11.8,5.4-18,17.5-15.5,30.2c2.5,12.6,12.7,21.5,25.5,22.1c0.1,0,0.1,0,0.2,0c0.8,0,4.4-0.2,41.8-2.6
			c16.3-1,33.1-2.1,34.6-2.2c4.7-0.2,8.6-4,8.7-8.8c0.1-5-3.8-9.2-8.9-9.3c-0.5,0-0.5,0-35.5,2.2c-17.5,1.1-37.1,2.4-40.4,2.6
			c-6.3-0.5-7.8-5.8-8.2-7.4c-0.7-3.4,0.3-7.9,5-10.2l82-29.7c0.2-0.1,0.4-0.2,0.7-0.3c28.8-13.1,66.4-1.3,70.9,0.1l49.1,24.2
			l-27,63.7c-13.3-8.2-29.5-10.4-44.5-5.9l-110.2,33.1c-13.1,4-27.6,2.5-39.7-4.1l-172.6-93.5c-6.2-3.4-4.2-9.3-3.7-10.5
			c0.6-1.6,3.2-6.6,9.5-4.9l134.1,44.7c4.7,1.6,9.9-1,11.5-5.7c1.6-4.8-1-9.9-5.7-11.5l-134.4-44.9c-0.2-0.1-0.3-0.1-0.5-0.1
			c-13.3-3.6-26.2,2.8-31.3,15.6c-5.2,12.9-0.3,26.5,11.9,33.2l172.7,93.8c16.3,8.9,35.8,10.9,53.5,5.5l110.2-33.1
			c10.1-3,21-1.6,29.9,4.1l56.2,35.4c0.3,0.2,0.5,0.3,0.8,0.4c0.1,0,0.1,0.1,0.2,0.1h0.1c0.1,0.1,0.2,0.1,0.4,0.1
			c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.3,0,0.4,0.1c0.4,0,0.7,0.1,1.1,0.1l0,0l0,0l0,0
			l0,0c0.4,0,0.8,0,1.2-0.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.5-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.2,0.7-0.3
			c0.1-0.1,0.3-0.1,0.4-0.2c0.2-0.1,0.4-0.2,0.7-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.3-0.2,0.4-0.3
			c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0,0,0-0.1,0.1-0.1
			c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.3-0.5,0.4-0.8l40-85.2c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.3-0.8
			c0.1-0.3,0.1-0.6,0.1-0.9s0.1-0.6,0.1-0.9C490.358,378.229,490.358,377.929,490.358,377.629z M469.358,382.229l-31.9,67.9
			l-32.3-20.4l27.7-65.4L469.358,382.229z" />
                                    <path d="M153.858,173.229h25.4c10.4,0,18.8,8.4,18.8,18.8c0,10.4-8.4,18.8-18.8,18.8h-45.7c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1
			h23.9v17.9c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1v-17.9h3.7c20.4,0,37-16.6,37-37s-16.6-37-37-37h-25.4c-10.4,0-18.8-8.4-18.8-18.8
			s8.4-18.8,18.8-18.8h44.9c5,0,9.1-4.1,9.1-9.1s-4.1-9.1-9.1-9.1h-23.1v-17.5c0-5-4.1-9.1-9.1-9.1s-9.1,4.1-9.1,9.1v17.6h-3.7
			c-20.4,0-37,16.6-37,37C116.958,156.629,133.558,173.229,153.858,173.229z" />
                                    <path d="M166.658,310.229c80.6,0,146.1-65.6,146.1-146.1s-65.6-146.2-146.1-146.2s-146.2,65.6-146.2,146.2
			S86.058,310.229,166.658,310.229z M166.658,36.129c70.6,0,128,57.4,128,128s-57.4,128-128,128s-128-57.4-128-128
			S96.058,36.129,166.658,36.129z" />
                                </g>
                            </g>
                        </svg>
                        <a routerLink="/prices" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Prices' | translate}}</a>
                    </span>

                    <span class="spaceBetweenMenus">
                        <svg height="24" viewBox="0 0 64 64" width="24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#78b9eb">
                                <path
                                    d="m57.45 28-1.45-4.47-1.45 4.47h-4.71l3.81 2.76-1.45 4.48 3.8-2.77 3.8 2.77-1.45-4.48 3.81-2.76z" />
                                <path
                                    d="m50.45 13-1.45-4.47-1.45 4.47h-4.71l3.81 2.76-1.45 4.48 3.8-2.77 3.8 2.77-1.45-4.48 3.81-2.76z" />
                                <path
                                    d="m38 47-2.03 11h-7.94l-2.03-11a17.277 17.277 0 0 0 -17 14h46a17.262 17.262 0 0 0 -4.98-9.02 16.927 16.927 0 0 0 -12.02-4.98z" />
                                <path d="m42 36a2.006 2.006 0 0 0 2-2v-1a2.006 2.006 0 0 0 -2-2h-1v5z" />
                                <path d="m44 26a8 8 0 0 0 -8-8h-8a8 8 0 0 0 -8 8v2l3 3v-3l7-3 11 3v3l3-3z" />
                                <path
                                    d="m33.45 7-1.45-4.47-1.45 4.47h-4.71l3.81 2.76-1.45 4.48 3.8-2.77 3.8 2.77-1.45-4.48 3.81-2.76z" />
                                <path d="m20 33v1a2.006 2.006 0 0 0 2 2h1v-5h-1a2.006 2.006 0 0 0 -2 2z" />
                                <path
                                    d="m16.45 13-1.45-4.47-1.45 4.47h-4.71l3.81 2.76-1.45 4.48 3.8-2.77 3.8 2.77-1.45-4.48 3.81-2.76z" />
                                <path
                                    d="m10.35 30.76 3.81-2.76h-4.71l-1.45-4.47-1.45 4.47h-4.71l3.81 2.76-1.45 4.48 3.8-2.77 3.8 2.77z" />
                            </g>
                            <path
                                d="m41.949 37h.051a3 3 0 0 0 3-3v-1a3 3 0 0 0 -1.826-2.76l1.533-1.533a1 1 0 0 0 .293-.707v-2a9.01 9.01 0 0 0 -9-9h-8a9.01 9.01 0 0 0 -9 9v2a1 1 0 0 0 .293.707l1.533 1.533a3 3 0 0 0 -1.826 2.76v1a3 3 0 0 0 3 3h.051a10 10 0 0 0 19.9 0zm1.051-3a1 1 0 0 1 -1 1v-3a1 1 0 0 1 1 1zm-22-8a7.009 7.009 0 0 1 7-7h8a7.009 7.009 0 0 1 7 7v1.586l-1 1v-.586a1 1 0 0 0 -.737-.965l-11-3a1 1 0 0 0 -.657.046l-7 3a1 1 0 0 0 -.606.919v.586l-1-1zm0 8v-1a1 1 0 0 1 1-1v3a1 1 0 0 1 -1-1zm11 10a8.009 8.009 0 0 1 -8-8v-7.341l6.073-2.6 9.927 2.705v7.236a8.009 8.009 0 0 1 -8 8z"
                                fill="#1a6fb0" />
                            <path
                                d="m11.805 36.236a1 1 0 0 0 .951-1.308l-1.229-3.782 3.216-2.337a1 1 0 0 0 -.588-1.809h-3.975l-1.229-3.78a1 1 0 0 0 -1.9 0l-1.231 3.78h-3.975a1 1 0 0 0 -.588 1.809l3.216 2.337-1.229 3.782a1 1 0 0 0 1.539 1.117l3.217-2.337 3.217 2.337a1 1 0 0 0 .588.191zm-2.405-5.164.5 1.546-1.314-.955a1 1 0 0 0 -1.176 0l-1.31.955.5-1.546a1 1 0 0 0 -.364-1.117l-1.314-.955h1.625a1 1 0 0 0 .951-.691l.502-1.545.5 1.545a1 1 0 0 0 .953.691h1.625l-1.314.955a1 1 0 0 0 -.364 1.117z"
                                fill="#1a6fb0" />
                            <path
                                d="m11.473 16.146-1.229 3.782a1 1 0 0 0 1.539 1.117l3.217-2.337 3.217 2.337a1 1 0 0 0 1.539-1.117l-1.229-3.782 3.216-2.337a1 1 0 0 0 -.588-1.809h-3.975l-1.229-3.78a1 1 0 0 0 -1.9 0l-1.231 3.78h-3.975a1 1 0 0 0 -.588 1.809zm2.074-2.146a1 1 0 0 0 .951-.691l.5-1.545.5 1.545a1 1 0 0 0 .951.691h1.625l-1.314.955a1 1 0 0 0 -.364 1.117l.5 1.546-1.314-.955a1 1 0 0 0 -1.176 0l-1.314.955.5-1.546a1 1 0 0 0 -.364-1.117l-1.306-.955z"
                                fill="#1a6fb0" />
                            <path
                                d="m63.106 27.691a1 1 0 0 0 -.951-.691h-3.975l-1.229-3.78a1 1 0 0 0 -1.9 0l-1.231 3.78h-3.975a1 1 0 0 0 -.588 1.809l3.216 2.337-1.229 3.782a1 1 0 0 0 1.539 1.117l3.217-2.337 3.217 2.337a1 1 0 0 0 1.539-1.117l-1.229-3.782 3.216-2.337a1 1 0 0 0 .363-1.118zm-5.706 3.381.5 1.546-1.314-.955a1 1 0 0 0 -1.176 0l-1.314.955.5-1.546a1 1 0 0 0 -.364-1.117l-1.31-.955h1.625a1 1 0 0 0 .951-.691l.5-1.545.5 1.545a1 1 0 0 0 .951.691h1.625l-1.314.955a1 1 0 0 0 -.36 1.117z"
                                fill="#1a6fb0" />
                            <path
                                d="m45.473 16.146-1.229 3.782a1 1 0 0 0 1.539 1.117l3.217-2.337 3.217 2.337a1 1 0 0 0 1.539-1.117l-1.229-3.782 3.216-2.337a1 1 0 0 0 -.588-1.809h-3.975l-1.229-3.78a1 1 0 0 0 -1.9 0l-1.231 3.78h-3.975a1 1 0 0 0 -.588 1.809zm2.074-2.146a1 1 0 0 0 .951-.691l.5-1.545.5 1.545a1 1 0 0 0 .951.691h1.625l-1.314.955a1 1 0 0 0 -.364 1.117l.5 1.546-1.314-.955a1 1 0 0 0 -1.176 0l-1.314.955.5-1.546a1 1 0 0 0 -.364-1.117l-1.306-.955z"
                                fill="#1a6fb0" />
                            <path
                                d="m28.473 10.146-1.229 3.782a1 1 0 0 0 1.539 1.117l3.217-2.337 3.217 2.337a1 1 0 0 0 1.539-1.117l-1.229-3.782 3.216-2.337a1 1 0 0 0 -.588-1.809h-3.975l-1.229-3.78a1 1 0 0 0 -1.9 0l-1.231 3.78h-3.975a1 1 0 0 0 -.588 1.809zm2.074-2.146a1 1 0 0 0 .951-.691l.502-1.545.5 1.545a1 1 0 0 0 .953.691h1.625l-1.314.955a1 1 0 0 0 -.364 1.117l.5 1.546-1.314-.955a1 1 0 0 0 -1.176 0l-1.314.955.5-1.546a1 1 0 0 0 -.364-1.117l-1.31-.955z"
                                fill="#1a6fb0" />
                            <path
                                d="m38 46h-12a18.344 18.344 0 0 0 -17.984 14.825 1 1 0 0 0 .984 1.175h46a1 1 0 0 0 .985-1.172 18.284 18.284 0 0 0 -5.258-9.555 17.88 17.88 0 0 0 -12.727-5.273zm-1.2 2-1.664 9h-6.27l-1.666-9zm-26.559 12a16.369 16.369 0 0 1 14.933-11.965l1.877 10.147a1 1 0 0 0 .983.818h7.932a1 1 0 0 0 .983-.818l1.876-10.142a15.871 15.871 0 0 1 10.488 4.648 16.853 16.853 0 0 1 4.441 7.312z"
                                fill="#1a6fb0" />
                        </svg>
                        <a routerLink="/clients" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Clients' | translate}}</a>
                    </span>

                    <span class="spaceBetweenMenus">
                        <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                            <path fill="transparent"
                                d="M58.92,143.62l-.38.73H205.47l-.38-.73a68.84,68.84,0,0,0-61.18-37.08H120.1A68.84,68.84,0,0,0,58.92,143.62Z" />
                            <path fill="#9bc9ff"
                                d="M220.19,148.35H43.81A8,8,0,0,0,36,157.89l13.57,69.34a8,8,0,0,0,7.85,6.46H206.63a8,8,0,0,0,7.85-6.46L228,157.89A8,8,0,0,0,220.19,148.35Z" />
                            <path fill="transparent" d="M136.66,102.54h0a45.4,45.4,0,1,0-9.31,0Z" />
                            <path fill="#9bc9ff"
                                d="M205.08,143.62a68.84,68.84,0,0,0-61.18-37.08h-10a68.84,68.84,0,0,1,61.18,37.08l.38.73h10Z" />
                            <path fill="#9bc9ff"
                                d="M220.19,148.35h-10a8,8,0,0,1,7.85,9.54l-13.57,69.35a8,8,0,0,1-7.85,6.46h10a8,8,0,0,0,7.85-6.46L228,157.89A8,8,0,0,0,220.19,148.35Z" />
                            <path fill="#9bc9ff"
                                d="M132,12a45.64,45.64,0,0,0-5,.28,45.38,45.38,0,0,1,0,90.21l.36,0h9.31A45.39,45.39,0,0,0,132,12Z" />
                            <path fill="#1e81ce"
                                d="M49.78,143.85h-10a12.5,12.5,0,0,0-12.27,14.9L41.11,228.1a12.53,12.53,0,0,0,12.27,10.1H202.63a12.52,12.52,0,0,0,12.27-10.1l13.57-69.34a12.5,12.5,0,0,0-12.27-14.9h-10A73.71,73.71,0,0,0,149,102.62a49.89,49.89,0,1,0-42,0A73.7,73.7,0,0,0,49.78,143.85Zm166.41,5a7.5,7.5,0,0,1,7.36,8.94L210,227.14a7.51,7.51,0,0,1-7.36,6.06H53.37A7.51,7.51,0,0,1,46,227.14L32.45,157.79a7.5,7.5,0,0,1,7.36-8.94H216.19ZM83.11,57.39A44.89,44.89,0,1,1,132.63,102h-9.26A45,45,0,0,1,83.11,57.39Zm33,49.65H139.9a68.67,68.67,0,0,1,60.74,36.81H55.36A68.67,68.67,0,0,1,116.1,107Z" />
                            <path fill="#1e81ce" d="M228.35,243.5H27.65a2.5,2.5,0,0,0,0,5h200.7a2.5,2.5,0,1,0,0-5Z" />
                        </svg>
                        <a routerLink="/jobs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Jobs' | translate}}</a>
                    </span>

                    <span class="spaceBetweenMenus">
                        <svg height="24" viewBox="0 -28 480 480" width="24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m8 40 232 144 232-144v-32h-464zm0 0" fill="#9bc9ff" />
                            <g fill="#1e81ce">
                                <path
                                    d="m0 0v368h224v-16h-208v-297.601562l224 139.015624 224-139.015624v305.601562h16v-360zm240 174.585938-224-139.035157v-19.550781h448v19.550781zm0 0" />
                                <path
                                    d="m344 216c-43.71875.003906-82.769531 27.347656-97.71875 68.433594-14.953125 41.082031-2.613281 87.128906 30.875 115.234375 33.488281 28.101562 80.980469 32.257812 118.84375 10.402343l-8-13.839843c-34.492188 19.914062-78.058594 14.179687-106.222656-13.980469-28.164063-28.160156-33.902344-71.726562-13.992188-106.21875s60.503906-51.316406 98.976563-41.015625c38.46875 10.300781 65.226562 45.15625 65.238281 84.984375v24c0 8.835938-7.164062 16-16 16s-16-7.164062-16-16v-72h-16v8.886719c-16.140625-16.542969-40.789062-21.46875-62.046875-12.40625-21.261719 9.066406-34.769531 30.265625-34.003906 53.363281.761719 23.101562 15.640625 43.359375 37.453125 51.003906 21.8125 7.640625 46.082031 1.09375 61.09375-16.480468 5.898437 14.046874 20.878906 21.984374 35.8125 18.984374 14.933594-3.003906 25.679687-16.117187 25.691406-31.351562v-24c-.066406-57.410156-46.589844-103.933594-104-104zm0 144c-22.089844 0-40-17.910156-40-40s17.910156-40 40-40 40 17.910156 40 40c-.027344 22.082031-17.917969 39.972656-40 40zm0 0" />
                            </g>
                        </svg>
                        <a routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="menuText">{{'App.Menu.Contact' | translate}}</a>
                    </span>
                </div>

                <div class="languageAndLogo">
                    <img *ngIf="lang === 'bg'" class="stickyLanguages flags" [ngClass]="{'flagsIsMobile': isMobile}"
                        [src]="'./assets/Icons/Bulgaria.webp'" alt="bg" loading="lazy">
                    <img *ngIf="lang === 'en'" class="stickyLanguages flags" [ngClass]="{'flagsIsMobile': isMobile}"
                        [src]="'./assets/Icons/England.webp'" alt="en" loading="lazy">
                    <img *ngIf="lang === 'de'" class="stickyLanguages flags" [ngClass]="{'flagsIsMobile': isMobile}"
                        [src]="'./assets/Icons/Germany.webp'" alt="de" loading="lazy">

                    <select id="language" class="stickyLanguages languages shadow"
                        [ngClass]="{'languagesIsMobile': isMobile}" (change)="selectedLanguage($event)" aria-labelledby="lblLanguages">
                        <option value="en">English</option>
                        <option value="bg">Bulgarian</option>
                        <option value="de">Germany</option>
                    </select>

                    <a routerLink="/home" class="stickyLogo logoText zoom" [ngClass]="{'logoTextIsMobile': isMobile}">
                        <img class="wh" [src]="'./assets/Images/logoTrans.png'" alt="HR services, hr agency, bpo, human resource, talent hunter, logoCSoft" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
        <!-- <div>
        <img class="banner" [src]="'./assets/Icons/baner.jpg'" alt="baner" height="300" width="100%">
    </div> -->

    </menu>

    <div class="footer footer-hover-zoom animationFooter">
        <div class="socialPages">
            <div class="positionAbsolute" [ngClass]="{'mobilePossitionMiddle': isMobile}">
                <span class="marginsFaceLinked valign colorWhite">
                    {{'App.Contacts.FollowUs' | translate}}
                </span>
                <span class="marginsFaceLinked posRelatZindex">
                    <a href="https://www.facebook.com/C-Soft-100495352046043/?view_public_for=100495352046043">
                        <img [src]="'./assets/Icons/facebook.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, facebook" width="24px" height="24px"
                            loading="lazy">
                    </a>
                </span>
                <span class="marginsFaceLinked posRelatZindex">
                    <a href="https://www.linkedin.com/company/csoft-hr-and-software-services/?viewAsMember=true"><img
                            [src]="'./assets/Icons/linkedin.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, linkedin" width="24px" height="24px"
                            loading="lazy"></a>
                </span>
                <span class="marginsFaceLinked posRelatZindex">
                    <a href="https://twitter.com/CSoftEurope">
                        <img [src]="'./assets/Icons/twitter.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, twitter" width="24px" height="24px"
                            loading="lazy">
                    </a>
                </span>


                <!-- <span class="marginsFaceLinked">
                    <a href="">
                        <img [src]="'./assets/Icons/xing.svg'" alt="xing" width="24px" height="24px">
                    </a>
                </span> -->
            </div>
            <div class="positionLeft">
                <span class="marginsFaceLinked valign colorWhite">
                    {{'App.Contacts.ShareUs' | translate}}
                </span>
                <span class="marginsFaceLinked">
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A//CSofteu.com"><img
                            [src]="'./assets/Icons/facebook.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, facebook" width="24px" height="24px"
                            loading="lazy"></a>
                </span>
                <span class="marginsFaceLinked">
                    <a
                        href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//CSofteu.com&title=HR%20outsourcing%20and%20Software%20services&summary=The%20best%20prices%20ever!&source="><img
                            [src]="'./assets/Icons/linkedin.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, linkedin" width="24px" height="24px"
                            loading="lazy"></a>
                </span>
                <span class="marginsFaceLinked">
                    <a href="https://twitter.com/intent/tweet?text=https%3A//CSofteu.com"><img
                            [src]="'./assets/Icons/twitter.svg'" alt="HR services, hr agency, bpo, human resource, talent hunter, twitter" width="24px" height="24px"
                            loading="lazy"></a>
                </span>

                <span *ngIf="!isMobile" class="copyRight positionRight allRightsReserved">© 2024 CSoft Europe. All rights
                    reserved.</span>
            </div>
            
        </div>
        <br>
        <div class="center">
            <div class="colorWhite displayFlex">
                <span>
                    <p>{{'App.Contacts.Address' | translate}}</p>
                    <p>{{'App.Contacts.CountryCity' | translate}}</p>
                </span>
                <span class="mr-100">
                    <p>{{'App.Contacts.Email' | translate}}</p>
                    <p>&#099;&#115;&#111;&#102;&#116;&#046;&#104;&#114;&#046;&#101;&#117;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
                        &#847;&#824;&#847;
                        &#099;&#115;&#111;&#102;&#116;&#101;&#117;&#064;&#099;&#115;&#111;&#102;&#116;&#101;&#117;&#046;&#099;&#111;&#109;
                    </p>
                    <!--EMAIL-->
                </span>
                <span>
                    <p>{{'App.Contacts.Tel' | translate}}</p>
                    <p> +359 878 340 413</p>
                </span>
            </div>
        </div>
    </div>
</div>

<br>
<br>
<br>