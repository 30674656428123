import { Component, OnInit } from '@angular/core';
import { ScreenResolutionService } from '../screen-resolution.service';

@Component({
  selector: 'app-actual-prices',
  templateUrl: './actual-prices.component.html',
  styleUrls: ['./actual-prices.component.scss']
})
export class ActualPricesComponent implements OnInit {
  public isMobile: boolean = false;
  constructor(screenResulution: ScreenResolutionService) {
    this.isMobile = screenResulution.isMobile();
  }

  ngOnInit(): void {
  }

}
