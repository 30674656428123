<ng-container id="modalDialog" *ngIf="displayEmail$ | async as displayEmail">
    <section [ngClass]="{'open': displayEmail === 'open'}">
        <div (click)="$event.stopPropagation()">
            <h1>{{'App.Prices.GiveEmail' | translate}}</h1>

            <p class="textBoxes">
                <label>{{'App.Contacts.Name' | translate}}</label>
                <input class="input shadow" type="text" id="clientName" size="50px" />
                <label class="redText" *ngIf="missingName">{{'App.Contacts.Required' | translate}}</label>
            </p>

            <p class="textBoxes">
                <label>{{'App.Contacts.Email' | translate}}</label>
                <input class="input shadow" id="clientEmail" type="email" size="50px" />
                <label class="redText" *ngIf="missingEmail">{{'App.Contacts.Required' | translate}}</label>
            </p>

            <p class="textBoxes">
                <label>{{'App.Contacts.Company' | translate}}</label>
                <input class="input shadow" id="clientCompany" type="text" size="50px" />
            </p>

            <button (click)="close(true)" id="send-button" class="buttonSize mb-15 shadow">OK</button>

            <button id="close-button" class="buttonSize shadow" (click)="close(false)">Cancel</button>


        </div>
    </section>
</ng-container>