<div class="fullServices">
    <div class="margins">
        <!-- <h3 class="text sizeMainText animateHeader bold">{{'App.Services.OurServices' | translate}}</h3> -->
        <title class="text sizeMainText animateHeader bold">{{'App.Services.OurServices' | translate}}</title>
        <div class="text sizeOtherText marginTop30 displayFlex"
            [ngClass]="{'marginsMobile': isMobile}">

            <div class="rectangleAnimation">
                <div class="img-hover-zoom shadow" [ngClass]="{'mobileimg-hover-zoom': isMobile}">
                    <a routerLink="/hrservices" class="colorBlack">
                        <div class="textPositionInImages" [ngClass]="{'mobileTextPositionInImages': isMobile}">
                            <h1 class="sizeMainText bold colorWhite">
                                {{'App.Services.HRServices' | translate}}
                            </h1>
                            <p class="sizeOtherText2 bold">
                                {{'App.Services.HRServicesText' | translate}}
                            </p>
                        </div>
                        <img [src]="'./assets/Images/hr.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, HRServices" height="600px" width="400px" loading="lazy">
                    </a>
                </div>
            </div>

            <br>

            <div class="rectangleAnimation2">
                <div class="img-hover-zoom shadow" [ngClass]="{'mobileimg-hover-zoom': isMobile}">
                    <a routerLink="/software-services" class="colorBlack">
                        <div class="textPositionInImages" [ngClass]="{'mobileTextPositionInImages': isMobile}">
                            <h1 class="sizeMainText bold colorWhite">
                                {{'App.Services.CreationSoftware' | translate}}
                            </h1>
                            <p class="sizeOtherText2 bold">
                                {{'App.Services.CreationSoftwareText' | translate}}
                            </p>
                        </div>

                        <img [src]="'./assets/Images/software.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, software" height="520px" width="400px" loading="lazy">
                    </a>
                </div>
            </div>

            <br>

            <div class="rectangleAnimation3">
                <div class="img-hover-zoom shadow" [ngClass]="{'mobileimg-hover-zoom': isMobile}">
                    <a routerLink="/training-services" class="colorBlack">
                        <div class="textPositionInImages" [ngClass]="{'mobileTextPositionInImages': isMobile}">
                            <h1 class="sizeMainText bold colorWhite">
                                {{'App.Services.Trainings' | translate}}
                            </h1>
                            <p class="sizeOtherText2 bold">
                                {{'App.Services.TrainingsText' | translate}}
                            </p>
                        </div>

                        <img [src]="'./assets/Images/training.webp'" alt="HR services, hr agency, bpo, human resource, talent hunter, training" height="520px" width="400px" loading="lazy">
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
<br>
<br>
<br>