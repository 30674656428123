import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogService } from './modal-dialog.service';
//import { FlashMessageService } from './flash-message.service';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {
  showLoadingIndicator = true;
  constructor(private translate: TranslateService,
    private modalService: ModalDialogService,
    private _router: Router
  ) {
    translate.setDefaultLang('en');

    //Display responsible
    var popupdisplayed = localStorage.getItem('cookieMessage');
    if (popupdisplayed !== "exist") {
      this.openCookie();
    }

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicator = true;
      }
      if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        this.showLoadingIndicator = false;
      }
    });
  }
  openCookie() {
    this.modalService.open();
  }

  title = 'CSoft';

  public useLanguage(language: string) {
    this.translate.use(language);
  }
}
