import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { ScreenResolutionService } from '../screen-resolution.service';
import { FlashMessageService } from '../flash-message.service';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-header-footer',
  templateUrl: './header-footer.component.html',
  styleUrls: ['./header-footer.component.scss']
})
export class HeaderFooterComponent implements OnInit {
  public lang: string;
  public isMenuOpened: boolean = false;
  public isMobile: boolean = false;
  public markActive: boolean = false;

  subscription: Subscription;
  text: string[];
  header: string;
  public messageNo: number;

  constructor(private router: Router, public app: AppComponent, screenResulution: ScreenResolutionService, private flashMessage: FlashMessageService) {
    this.isMobile = screenResulution.isMobile();
  }

  ngOnInit(): void {
    this.lang = "en";
    // this.showFlashMessages(); //Looping message
  }

  showFlashMessages() {
    //Rotate popup flash message
    this.messageNo = 0;
    const source = interval(45000); //hidding for seconds
    this.header = "Our last clients:\n"
    this.text = [
      'Company: Uniglass \nCountry: Bulgaria \nServices: HR services',
      'Company: IDS \nCountry: Sweden \nServices: HR services',
      'Company: Pixel Frost \nCountry: USA \nServices: HR services'];
    this.subscription = source.subscribe(val => this.flashMessage.show(this.checkFlashMessageContent(this.messageNo++), ''));

  }

  checkFlashMessageContent(number: number): string {
    if (this.text[number] !== undefined) { return this.header + this.text[number] }
    this.messageNo = 0;
    return this.header + this.text[this.messageNo];
  }

  selectedLanguage(event: any) {
    this.lang = event.target.value;
    this.app.useLanguage(this.lang);
  }

  mobileMenuClick() {
    this.isMenuOpened = this.isMenuOpened === false ? true : false;
  }
}


