import { Component, OnInit } from '@angular/core';
import { ScreenResolutionService } from '../screen-resolution.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-email-marketing',
  templateUrl: './send-email-marketing.component.html',
  styleUrls: ['./send-email-marketing.component.scss']
})
export class SendEmailMarketingComponent implements OnInit {
  form: FormGroup;
  form2: FormGroup;
  public isMobile: boolean = false;
  public isLogged: boolean = false;
  public isWrongUser: boolean = false;

  constructor(private _fb: FormBuilder, private _fb2: FormBuilder, public httpCli: HttpClient, screenResulution: ScreenResolutionService,) {
    this.isMobile = screenResulution.isMobile();
  }

  ngOnInit(): void {
    this.createForm();
    this.createForm2();
    this.positionEmailProperty().setErrors(null);
    this.positionPassProperty().setErrors(null);

    this.positionTextProperty().setErrors(null);
    this.positionToProperty().setErrors(null);
  }

  createForm() {
    this.form = this._fb.group({
      loginEmail: [null, [Validators.required]],
      loginPass: [null, [Validators.required]],
    });
    this.form.get("loginEmail").markAsTouched();
    this.form.get("loginPass").markAsTouched();
  }

  createForm2() {
    this.form2 = this._fb2.group({
      sendText: [null, [Validators.required]],
      sendTo: [null, [Validators.required]],
    });
    this.form2.get("sendText").markAsTouched();
    this.form2.get("sendTo").markAsTouched();
  }

  login() {
    if (this.form.valid) {
      if (this.positionEmailProperty().value === "csoft.hr.eu@gmail.com" && this.positionPassProperty().value === "csoft") {
        this.isLogged = true;
        this.isWrongUser = false;
      }
      else {
        this.isWrongUser = true;
      }
    }
    else {
      if (!this.form.get("loginEmail").valid) {
        this.positionEmailProperty().setErrors({ 'empty': true });
      }
      if (!this.form.get("loginPass").valid) {
        this.positionPassProperty().setErrors({ 'empty': true });
      }
    }
  }

  send() {
    if (this.form.valid) {

      let allEmails = this.positionToProperty().value.split(";");
      for (var i = 0; i < allEmails.length; i++) {
        var currEmail = allEmails[i].trim();

        //Start sending  
        let result: boolean = false;
        //Send data to the backend server
        this.httpCli.post<boolean>('/api/sendMailMarketing', { text: this.positionTextProperty().value, to: currEmail, htmlText: this.positionTextProperty().value }).subscribe(data => {
          result = data;
          if (result.toString().includes('Thank you!')) {
            //alert('Thank you!');
          }
          else {
            //alert('Sorry. Please contact us via our E-mail!');
          }
        })
      }
      alert('The marketing email is sent to all emails!');
    }
    else {
      if (!this.form.get("sendText").valid) {
        this.positionTextProperty().setErrors({ 'empty': true });
      }
      if (!this.form.get("sendTo").valid) {
        this.positionToProperty().setErrors({ 'empty': true });
      }
    }
  }

  private positionEmailProperty() {
    return this.form.get('loginEmail');
  }
  private positionPassProperty() {
    return this.form.get('loginPass');
  }

  private positionTextProperty() {
    return this.form2.get('sendText');
  }
  private positionToProperty() {
    return this.form2.get('sendTo');
  }
}
