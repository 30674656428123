<div>
    <div class="fullTeam">
        <div>
            <h3 class="text">Team:</h3>
            <title class="text">Team:</title>
        </div>
    </div>


    <table>
        <tr>
            <td>
                <div>
                    <div>
                        <img [src]="'./assets/Icons/ceco.jpg'" alt="HR services, hr agency, bpo, human resource, talent hunter, CEO" height="25%" width="25%" loading="lazy">
                    </div>
                    <div class="text">
                        CEO of the company
                    </div>
                </div>
            </td>
        </tr>

        <tr>
            <td>
                <div>
                    <div>
                        <img [src]="'./assets/Icons/iv.jpg'" alt="HR services, hr agency, bpo, human resource, talent hunter, CEO" height="25%" width="25%" loading="lazy">
                    </div>
                    <div class="text">
                        Marceting director
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
<br>
<br>
<br>